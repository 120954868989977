import { get } from 'idb-keyval';

import {
  type IAirwebTicketLegacyItemDocument,
  type IAirwebTicketLegacyUserInfo,
} from '@airweb/ticket-legacy-client';

import { type DynamicForm } from './school';
import { type ProductItem } from '~/types/product';
import type { Base64File } from '~/types/file';

export type UserDocuments = Record<string, Base64File | string | null>;
export interface ChildInformations {
  userPicture: File | null;
  userLastname: string;
  userName: string;
  userMail: string;
  userPhoneNumber: string;
}

export const useRenew = defineStore('renew', {
  state: () => ({
    currentChild: null as null | Partial<IAirwebTicketLegacyUserInfo>,
    product: null as null | Partial<ProductItem>,
    dynamicForm: {} as DynamicForm,
    documents: {} as UserDocuments,
    childInformations: {
      userPicture: null as null | string,
      userPictureName: '',
      userPictureType: '',
      userLastname: '',
      userName: '',
      userMail: '',
      userPhoneNumber: '',
      userBirthday: '',
    },
    hydrationCompleted: true,
  }),

  getters: {
    hasDocuments: (state) => {
      return Boolean(state.product?.documents?.length);
    },
    requiredDocumentsByProduct: (state): IAirwebTicketLegacyItemDocument[] => {
      return state.product?.documents ?? [];
    },
  },

  actions: {
    setCurrentChild(profile: Partial<IAirwebTicketLegacyUserInfo>): void {
      this.currentChild = profile;
    },
    updateDocuments(documents?: UserDocuments): void {
      this.documents = documents ?? {};
    },
    updateMetadatas(metadatas?: DynamicForm): void {
      this.dynamicForm = metadatas ?? {};
    },
    updateProduct(product?: Partial<ProductItem>): void {
      this.product = product ?? null;
    },
    updateDynamicForm(form: DynamicForm) {
      this.dynamicForm = form
    },
    updateChildInformations(infos: {
      userPicture: string,
      userPictureName: string,
      userPictureType: string,
      userLastname: string,
      userName: string,
      userMail: string,
      userPhoneNumber: string,
      userBirthday: string,
    }): void {
      this.childInformations = infos;
    }
  },

  async hydrate(state) {
    state.hydrationCompleted = false;
    const rawRenewFromState = (await get<string|typeof state>('renew').catch(() => '{}')) ?? '{}';
    let renewFromState: (typeof state)|null = null;
    
    // Check if in the indexdb we still have an object (old version) 
    // otherwise we've upgraded to the new stringify version
    if (typeof rawRenewFromState === 'object') {
      renewFromState = rawRenewFromState;
    } else {
      renewFromState = JSON.parse(rawRenewFromState);
    }
    
    state.currentChild = renewFromState?.currentChild ?? null;
    state.product = renewFromState?.product ?? null;
    state.dynamicForm = renewFromState?.dynamicForm ?? {};
    state.childInformations = renewFromState?.childInformations ?? {
      userPicture: null,
      userPictureName: '',
      userPictureType: '',
      userLastname: '',
      userName: '',
      userMail: '',
      userPhoneNumber: '',
      userBirthday: '',
    };
    state.documents = renewFromState?.documents ?? {};
    state.hydrationCompleted = true;
  },
});
