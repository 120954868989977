<script lang="ts" setup>
interface Props {
  status?: 'SUCCESS' | 'DANGER' | 'WARNING' | 'INFO' | 'BASE';
  icon?: string;
  withIcon?: boolean;
  withBorder?: boolean;
  disposable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  status: 'INFO',
  icon: 'mdi:alert-box',
  withIcon: true,
  withBorder: true,
  disposable: true,
});

const emit = defineEmits(['dismiss']);

const showAlert = ref(true);

const statusClasses = computed(() => {
  let classList = [];
  const borderClass = 'border-2';
  
  switch (props.status) {
    case 'SUCCESS':
      classList = ['text-green-800', 'bg-green-200'];
      return props.withBorder ? [...classList, borderClass, 'border-green-500'] : classList;
    case 'WARNING':
      classList = ['text-orange-800', 'bg-orange-200'];
      return props.withBorder ? [...classList, borderClass, 'border-orange-500'] : classList;
    case 'DANGER':
      classList = ['text-red-800', 'bg-red-200'];
      return props.withBorder ? [...classList, borderClass, 'border-red-500'] : classList;
    case 'BASE':
      classList = ['text-neutral-800', 'bg-neutral-200'];
      return props.withBorder ? [...classList, borderClass, 'border-neutral-500'] : classList;
    default:
      classList = ['text-blue-800', 'bg-blue-200'];
      return props.withBorder ? [...classList, borderClass, 'border-blue-500'] : classList;
  }
});

function dismiss() {
  showAlert.value = false;
  emit('dismiss');
}
</script>

<template>
  <!-- TODO: Animate -->
  <div
    v-if="showAlert"
    role="alert"
    class="relative flex items-center rounded py-4 pl-6 pr-10"
    :class="statusClasses"
  >
    <button
      v-if="props.disposable"
      type="button"
      class="absolute right-0 top-0 m-3"
      @click="dismiss"
    >
      <span class="sr-only">{{ $t('otherslabels.close_alert_text') }}</span>
      <Icon name="mdi:close" class="h-6" />
    </button>

    <Icon v-if="props.withIcon" :name="props.icon" class="hidden h-10 w-10 md:block" />

    <p class="flex-1" :class="{ ' md:ml-6': props.withIcon }">
      <slot />
    </p>
  </div>
</template>
