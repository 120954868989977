export function hasDeliveryChoice(supports: string[]): boolean {
  return supports.length > 1;
}
export function isDematerialized(supports: string[]): boolean {
  return supports.includes('DEMATERIALIZED');
}
export function isPhysical(supports: string[]): boolean {
  return supports.includes('PHYSICAL');
}
export function isMedia(supports: string[]): boolean {
  return supports.includes('MEDIA');
}
export function isClickAndCollect(supports: string[]): boolean {
  return supports.includes('COLLECT');
}
export function isShipping(supports: string[]): boolean {
  return supports.includes('SHIPPING');
}


export const DEFAULT_DELIVERY = 'PHYSICAL';