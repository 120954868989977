import * as Sentry from '@sentry/vue';
import { type Router } from 'vue-router';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  let tracePropagationTargets: string[] = [];

  if (config.public.sentryTracePropagationTarget) {
    try {
      tracePropagationTargets = JSON.parse(config.public.sentryTracePropagationTarget);
    } catch {}
  }

  Sentry.init({
    enabled: Boolean(config.public.sentryEnabled),
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    dsn: config.public.sentryDsn,
    debug: config.public.env !== 'production',
    release: config.public.sentryRelease,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router as Router),
        tracePropagationTargets,
      }),
      new Sentry.Replay(),
    ],
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
  });

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  };
});
