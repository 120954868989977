<script setup lang="ts">
import { set } from 'idb-keyval';
import { micromark } from 'micromark';

import { useAuth } from '@/store/auth';
import { useCart } from '@/store/cart';
import { useRenew } from '@/store/renew';
import { useSchool } from '@/store/school';
import { useNetwork } from '@/store/network';
import { useUpKeep } from '@/store/upkeep';

const cart = useCart();
const auth = useAuth();
const route = useRoute();
const router = useRouter();
const renew = useRenew();
const school = useSchool();
const network = useNetwork();
const upKeep = useUpKeep();
const nuxtApp = useNuxtApp();
const localePath = useLocalePath();

// Doc: https://nuxt.com/docs/api/composables/use-head#usehead
useHead({
  title: network.config.name,
  link: [{ rel: 'icon', href: network.config.logo ?? '/assets/favicon.ico' }],
  htmlAttrs: {
    style: 'scroll-padding-top: 100px;',
  }
});

if (process.client) {
  cart.$subscribe(
    (_, state) => {
      const persistedState = toRaw(state);
      console.log({ persistedState });
      set('cart', JSON.stringify(persistedState)).catch((error) => {
        console.log({ error });
        nuxtApp.$sentryCaptureException(error);
      });
      
    },
    { detached: true, deep: true, immediate: false },
  );

  renew.$subscribe(
    (_, state) => {
      const persistedState = toRaw(state);
      set('renew', JSON.stringify(persistedState)).catch((error) => {
        nuxtApp.$sentryCaptureException(error);
      });
    },
    { detached: true, deep: true, immediate: false },
  );

  school.$subscribe(
    (_, state) => {
      const persistedState = toRaw(state);
      set('school', JSON.stringify(persistedState)).catch((error) => {
        nuxtApp.$sentryCaptureException(error);
      });
    },
    { detached: true, deep: true, immediate: false },
  );
}

const { data: colors } = useAsyncData(
  async () => {
    const primary = network.config.colors?.primary ?? '#0099CC ';
    const secondary = network.config.colors?.secondary ?? '#D73277';

    function invertColor(hex: string, bw?: boolean): string {
      const sanitizedHex = hex.startsWith('#') ? hex.slice(1) : hex;
      const fullHex =
        sanitizedHex.length === 3 ? sanitizedHex.replace(/(.)/g, '$1$1') : sanitizedHex;
      const hexMatch = fullHex.match(/.{2}/g);
      if (!hexMatch) {
        throw new Error('Invalid HEX color.');
      }
      const [r, g, b] = hexMatch.map((value) => parseInt(value, 16));
      if (bw) {
        // https://stackoverflow.com/a/3943023/112731
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
      }
      // invert color components
      const invertedR = (255 - r).toString(16).padStart(2, '0');
      const invertedG = (255 - g).toString(16).padStart(2, '0');
      const invertedB = (255 - b).toString(16).padStart(2, '0');
      // pad each with zeros and return
      return `#${invertedR}${invertedG}${invertedB}`;
    }

    // calculate the color contrast of the primary color which is an hex color
    const primaryContrast = invertColor(primary, true);
    const secondaryContrast = invertColor(secondary, true);

    return {
      '--primary': primary,
      '--primary-contrast': primaryContrast,
      '--secondary': secondary,
      '--secondary-contrast': secondaryContrast,
    };
  },
  {
    default() {
      return {};
    },
  },
);

const isFetchingUser = ref(false);

/**
 * This function will basically be executed everytime
 * a user switches tab or is focusing the page (useful if the user has two screens)
 */
async function handleWindowFocus() {
  // Bail early if the user is already verified
  if (auth.isAccountVerified) {
    return;
  }

  // Bail early if the user doesn't have an email
  if (!auth.user?.userMail) {
    return;
  }

  const isVisible = document.visibilityState === 'visible';

  // Bail early if the user is leaving the page
  if (!isVisible) {
    return;
  }

  // Bail early if we are already fetching the user
  if (isFetchingUser.value) {
    return;
  }

  try {
    isFetchingUser.value = true;
    const userToken = auth.user.userToken;
    const user = await nuxtApp.$ticket.account.getUser(userToken, undefined, {
      networkId: network.config.id
    });

    auth.user = user;
  } catch (e) {
    nuxtApp.$sentryCaptureException(e);
  } finally {
    isFetchingUser.value = false;
  }
}

onMounted(async () => {
  window.addEventListener('focus', handleWindowFocus);
  document.addEventListener('visibilitychange', handleWindowFocus);
  
  verifyMaintenanceAlert();
});

onUnmounted(() => {
  window.removeEventListener('focus', handleWindowFocus);
  document.removeEventListener('visibilitychange', handleWindowFocus);
});

const promoTextMd = computed(() => {
  // @ts-expect-error: Missing type in SDK or SDK not up to date :shrug:
  return network.config.data?.['school-configuration']?.['mardownPromoContent'];
});

const isMaintenancePage = computed(() => {
  return route.path === '/maintenance';
});

watch(() => route.path, () => {
  verifyMaintenanceAlert();
})

async function verifyMaintenanceAlert() {
  await upKeep.getAvailableMaintenanceAlerts(+network!.config!.id);
    
  if (upKeep.globalAlert) {
    router.push(localePath('maintenance'));
  }
}
</script>

<template>
  <div class="flex min-h-screen flex-col" :style="colors">
    <AppHeader v-if="!isMaintenancePage" />

    <div
      v-if="promoTextMd && ['/', '/cart/checkout'].includes(route.path)"
      role="alert"
      class="container p-4"
    >
      <div
        v-html="micromark(promoTextMd)"
        class="prose prose-sm max-w-full rounded bg-blue-200 p-4 text-black prose-li:marker:text-black"
      ></div>
    </div>

    <AppHelpSidebar v-if="!isMaintenancePage" />

    <main class="flex-1">
      <NuxtPage />
    </main>

    <AppFooter v-if="!isMaintenancePage" />
    
    <ModalReactivateAccount />

    <div id="modal"></div>
  </div>
</template>
