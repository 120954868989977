import revive_payload_client_uqmYaqbbua from "/app/node_modules/.pnpm/nuxt@3.7.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.6.0_encoding@0.1.13__yazd2eeqodrxtke4wl6xua475e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_yxUL8qxOlR from "/app/node_modules/.pnpm/nuxt@3.7.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.6.0_encoding@0.1.13__yazd2eeqodrxtke4wl6xua475e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_K5OZyJM9hi from "/app/node_modules/.pnpm/nuxt@3.7.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.6.0_encoding@0.1.13__yazd2eeqodrxtke4wl6xua475e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_7Wor7THsfy from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.17.2_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_p15oISC8JF from "/app/node_modules/.pnpm/nuxt@3.7.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.6.0_encoding@0.1.13__yazd2eeqodrxtke4wl6xua475e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_Qv22ukt8sv from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_rollup@4.17.2_vue-router@4.3.2_vue@3.3.4__vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_1w0xweWwvW from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_rollup@4.17.2_vue-router@4.3.2_vue@3.3.4__vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_badisWylaS from "/app/node_modules/.pnpm/nuxt@3.7.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.6.0_encoding@0.1.13__yazd2eeqodrxtke4wl6xua475e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _4_upKeep_HgmgosZx5b from "/app/plugins/4.upKeep.ts";
import _2_firebase_R5rFoiZKOj from "/app/plugins/2.firebase.ts";
import _3_sentry_client_ts_IsRn89mD46 from "/app/plugins/3.sentry.client.ts.ts";
import _1_airweb_lneS4MmBbC from "/app/plugins/1.airweb.ts";
import _4_clarity_client_8iHh3wh2u3 from "/app/plugins/4.clarity.client.ts";
export default [
  revive_payload_client_uqmYaqbbua,
  unhead_yxUL8qxOlR,
  router_K5OZyJM9hi,
  plugin_vue3_7Wor7THsfy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_p15oISC8JF,
  composition_Qv22ukt8sv,
  i18n_1w0xweWwvW,
  chunk_reload_client_badisWylaS,
  _4_upKeep_HgmgosZx5b,
  _2_firebase_R5rFoiZKOj,
  _3_sentry_client_ts_IsRn89mD46,
  _1_airweb_lneS4MmBbC,
  _4_clarity_client_8iHh3wh2u3
]