{
  "date_format": {
    "date_format": ""
  },
  "pages": {
    "404": {
      "404_title": "404",
      "page_not_found_label": "Oups... cette page est introuvable",
      "text_label": "Malgré toutes nos recherches la page demandée semble introuvable.",
      "back_tohome_btn": "Revenir sur la page d'accueil"
    },
    "header": {
      "logo_alt": "Logo représentant le réseau",
      "logo_text": "Lien vers la page d'accueil",
      "cart_btn_alt": "Accéder au panier",
      "tracking_btn": "Suivi des demandes",
      "account_btn": "Mon compte",
      "logout_btn": "Déconnexion",
      "tracking_desc": "voir le statut des inscriptions",
      "account_desc": "gérer mon compte",
      "profiles_btn": "ENFANTS ABONNéS",
      "profiles_desc": "gérer les profils enfants",
      "technical_difficulty": "Vous rencontrez des difficultés techniques ?",
      "click_on": "Cliquez sur",
      "this_link": "ce lien",
      "restart_procedure": "pour relancer la procédure."
    },
    "footer": {
      "logo_airweb_alt": "Logo représentant la société Airweb",
      "logo_airweb_sr": "Lien vers le site d'airweb",
      "legal_notice": "Mentions légales",
      "contact": "Contact",
      "lang_selector_placeholder": "Sélecteur de langue",
      "powered_by": "Propulsé par",
      "version": "v{version}"
    },
    "school_index": {
      "title": "Boutique scolaire {0}",
      "btn_register": "Je m'inscris pour la première fois",
      "btn_login": "Je possède déjà un compte"
    },
    "home": {
      "urban_store_alt": "Illustration représentant un arrêt de bus",
      "urban_store_btn": "Accéder à la boutique urbaine",
      "school_store_alt": "Illustration représentant deux camarades de classe",
      "school_store_btn": "Accéder à la boutique scolaire"
    },
    "auth_login": {
      "title": "Accéder au compte",
      "alert_text": "Connectez-vous pour accéder à votre suivi des commandes ou renouveler un abonnement",
      "login_label": "Identifiant (email)",
      "login_placeholder": "Entrer votre identifiant (email)",
      "password_label": "Mot de passe",
      "password_placeholder": "Entrer votre mot de passe",
      "password_reset_link": "Mot de passe oublié ?",
      "submit_label": "Se connecter",
      "alert_catch_all": "Erreur lors de la connexion",
      "user_deactivated": "Ce compte a été désactivé. Veuillez contacter votre réseau",
      "alert_user_disabled": "Cet utilisateur est momentanément bloqué",
      "alert_login_incorrect": "Cette combinaison identifiant/mot de passe n’est pas reconnu",
      "alert_too_many_attempts": "Votre compte est temporairement bloqué. Veuillez réessayé ultérieurement"
    },
    "school_register_child": {
      "title": "Informations scolaires",
      "school_travel_title": "Informations sur le trajet de l’enfant",
      "zone_name_label": "Lieu d’habitation de l’enfant",
      "zone_name_placeholder": "Veuillez sélectionner parmi la liste le lieu d'habitation de l'enfant",
      "school_zone_label": "Zone de l’établissement scolaire",
      "school_zone_placeholder": "Veuillez sélectionner la zone de l’établissement scolaire d’inscription",
      "school_schooling_title": "Informations sur la scolarité",
      "school_level_label": "Niveau de scolarité de l’enfant",
      "school_level_placeholder": "Sélectionner le niveau de scolarité de l’enfant",
      "school_name_label": "Nom de l’établissement scolaire",
      "school_name_placeholder": "Veuillez sélectionner parmis la liste le nom de l’établissement scolaire d’inscription",
      "school_class_label": "Classe d’inscription",
      "school_class_placeholder": "Veuillez sélectionner la classe d’inscription de l’enfant",
      "school_retaurant_label": "Régime scolaire",
      "school_retaurant_placeholder": "Veuillez sélectionner le régime scolaire de l’enfant",
      "school_busline_label": "Ligne de bus",
      "school_busline_placeholder": "Veuillez sélectionner la ligne de bus empruntée",
      "mmph_label": "Prise en charge MMPH",
      "mmph_yes_label": "L’enfant bénéficie d’une prise en charge",
      "mmph_no_label": "L’enfant ne bénéficie pas d’une prise en charge",
      "social_fund_label": "Prise en charge par le fond social",
      "social_fund_yes_label": "L’enfant bénéficie d’une prise en charge",
      "social_fund_no_label": "L’enfant ne bénéficie pas d’une prise en charge",
      "back_button": "Retour à l'étape précédente",
      "submit_button": "Étape suivante",
      "cart_title": "Votre panier",
      "steps_title": "Étapes",
      "empty_cart": "Panier vide",
      "info_contact": "Cas particulier ? Famille nombreuse ? Arrivée récente sur le territoire ? N’hésitez pas à nous contacter"
    },
    "school_personal_information": {
      "title": "Informations personnelles de l'enfant",
      "documents_section_title": "Pièces justificatives",
      "personal_information_section_title": "Informations personnelles",
      "profile_picture_label": "Photo de profil de l’abonnement",
      "lastname_label": "Nom de famille",
      "lastname_placeholder": "Veuillez saisir le nom de famille de l’enfant",
      "firstname_label": "Prénom",
      "firstname_placeholder": "Veuillez saisir le prénom de l’enfant",
      "relation_label": "Lien de parenté du responsable légal",
      "relation_placeholder": "Veuillez sélectionner le lien de parenté du responsable légal avec l’enfant",
      "contact_section_title": "Informations de contact",
      "street_label": "Adresse postale",
      "street_placeholder": "Veuillez saisir l’adresse postale de résidence de l’enfant",
      "postalcode_label": "Code postal",
      "postalcode_placeholder": "Veuillez saisir le code postale de résidence de l’enfant",
      "city_label": "Ville",
      "city_placeholder": "Veuillez saisir la ville de résidence",
      "country_label": "Pays",
      "country_placeholder": "Veuillez saisir le pays de résidence",
      "email_label": "Email",
      "email_placeholder": "Veuillez saisir l’adresse email de contact de l’enfant",
      "phone_label": "Téléphone",
      "phone_placeholder": "Veuillez saisir le numéro de téléphone de contact de l‘enfant",
      "documents_upload_placeholder": "Télécharger le document : {0}",
      "add_child_button": "Ajouter un enfant",
      "alternating_custody_label": "Garde alternée",
      "alternating_custody_yes": "L'enfant est en garde alternée",
      "alternating_custody_no": "L'enfant n'est pas en garde alternée",
      "alternating_custody_section_title": "Information sur la garde alternée",
      "alternating_custody_client_zone2_label": "Deuxième lieu d'habitation de l'enfant",
      "alternating_custody_client_zone2_placeholder": "Veuillez sélectionner le second lieu d'habitation de l'enfant",
      "alternating_custody_busline2_label": "Ligne de bus",
      "alternating_custody_busline2_placeholder": "Ligne de bus depuis le deuxième domicile",
      "no_data_found": "Aucune information trouvée",
      "download_model": "Télécharger le modèle",
      "student_birthday": "Veuillez saisir la date de naissance de l'élève",
      "max_file_size_exceeded": "Le fichier est trop volumineux",
      "wrong_file_type": "Le type du fichier est incorrect",
      "error_upload_file": "Une erreur est survenue lors de l'upload du fichier",
      "error_message_field_required": "Tous les champs requis ne sont pas remplis, veuillez renseigner les champs manquants avant de passer à l'étape suivante",
      "collect_message": "Ces informations sont nécessaires pour la création de la carte de transport de l'élève",
      "collect_message_renew": "Ces informations sont nécessaires pour la création ou le renouvellement de la carte de transport de l'élève"
    },
    "school_products": {
      "title": "Choix du titre",
      "noresult_title": "Aucun résultat",
      "noresult_text": "Aucun résultat correspondant à votre recherche n'a été trouvé.\nVeuillez modifier vos critères dans l'étape précédente ou contacter votre réseau.",
      "demat_label": "Sur mon application mobile",
      "demat_description": "Utilisation immédiate avec l'application mobile",
      "physical_label": "Sur ma carte de transport",
      "click_and_collect_label": "En click and collect",
      "click_and_collect_description": "Livraison en agence de mobilité",
      "shipping_label": "À domicile",
      "shipping_description": "Livraison chez vous"
    },
    "school_sidebar": {
      "schooling_title": "Informations scolaires",
      "schooling_description": "Renseigner les informations liées au trajet de l’enfant et son inscription dans un établissement scolaire",
      "product_title": "Choix du titre",
      "product_description": "Sélectionner le titre souhaité pour l’enfant",
      "information_title": "Informations personnelles",
      "information_description": "Télécharger les pièces justificatives et saisir les informations personnelles de l’enfant",
      "download_explain": "Téléchargez les pièces justificatives et saisissez les informations personnelles de l’élève."
    },
    "summary": {
      "title": "Récapitulatif d'inscription",
      "count_child": "Enfant {0}"
    },
    "school_register_help": {
      "title": "Besoin d’aide sur votre inscription ?",
      "description": "N’hésitez pas à nous contacter au numéro suivant",
      "illustration_alt": "Illustration d'une personne portant un point d'interrogation"
    },
    "cart": {
      "title": "Panier",
      "sidebar_title": "Votre panier",
      "sidebar_total": "Total",
      "sidebar_terms": "J'accepte les {sidebar_terms_link}",
      "sidebar_terms_link": "CGV",
      "sidebar_order_btn": "Commander",
      "cart_item_verify_btn": "Vérifier les infos.",
      "cart_item_delete_btn": "Supprimer",
      "cart_item_profile_picture_alt": "Photo de profil de l'enfant",
      "cart_item_product_picture_alt": "Photo du titre choisi",
      "empty_cart": "Panier vide...",
      "add_child_text": "Complétez l’inscription pour un nouvel enfant pour constituer un unique panier et réaliser un seul paiement !",
      "add_child_btn": "INSCRIRE UN AUTRE ENFANT",
      "add_child_img_alt": "Un enfant jouant avec une voiture",
      "delete_item_title": "Souhaitez-vous vraiment supprimer ce produit ?",
      "delete_item_text": "Supprimer le produit entraînera la perte des informations et pièces-justificatives saisies auparavant pour cet enfant.",
      "cancel_item_btn": "Annuler",
      "delete_item_btn": "Supprimer",
      "add": "Ajouter",
      "discount_codes": "Codes de réduction",
      "discount_codes_not_applied": "Les codes de réduction suivants n'ont pas pu être appliqués :",
      "code": "Code",
      "error_unknown": "Le code est inconnu",
      "error_code_expired": "Le code est expiré",
      "error_code_inactive": "Le code n'est plus valide",
      "error_code_rules_not_met": "Le code ne peut pas être appliqué à la commande actuelle",
      "error_code_limit_reached": "Le code a atteint son nombre maximum d’utilisation",
      "discount_code": "Code de réduction",
      "discount_code_placeholder": "Entrez votre code de réduction",
      "discount": "Réduction",
      "dematerialized": "Sur mon appli",
      "collect": "Click and collect",
      "media": "Sur ma carte",
      "shipping": "Livraison à domicile",
      "delivery": "Lieu de livraison",
      "home_delivery": "En livraison à domicile :"
    },
    "cart_account": {
      "title": "Création de compte",
      "info_title": "Informations personnelles du responsable légal",
      "info_picture_label": "Photo de profil",
      "info_picture_alt": "",
      "info_lastname_label": "Nom",
      "info_lastname_placeholder": "Veuillez saisir le nom de famille du responsable légal",
      "info_firstname_label": "Prénom",
      "info_firstname_placeholder": "Veuillez saisir le prénom du responsable légal",
      "info_birthday_label": "Date de naissance",
      "info_birthday_placeholder": "Veuillez sélectionner la date de naissance du responsable légal",
      "contact_title": "Informations de contact",
      "contact_street_label": "Adresse postale",
      "contact_street_placeholder": "Veuillez saisir l’adresse postale du responsable légal",
      "contact_postalcode_label": "Code postal",
      "contact_postalcode_placeholder": "Veuillez saisir le code postale de résidence du responsable légal",
      "contact_city_label": "Ville",
      "contact_city_placeholder": "Veuillez saisir la ville de résidence",
      "contact_country_label": "Pays",
      "contact_country_placeholder": "Veuillez saisir le pays de résidence",
      "contact_phone_label": "Téléphone",
      "contact_phone_placeholder": "Veuillez saisir le numéro de téléphone de contact du responsable légal",
      "login_title": "Identifiants de connexion",
      "login_email_label": "Email",
      "login_email_placeholder": "Veuillez saisir l’email qui vous servira d’identifiant (non modifiable)",
      "login_password_label": "Mot de passe",
      "login_password_placeholder": "Veuillez saisir le mot de passe de connexion",
      "login_confirm_label": "Confirmation du mot de passe",
      "login_confirm_placeholder": "Veuillez confirmer le mot de passe",
      "login_email_confirm": "Confirmation de l'email",
      "login_email_confirm_placeholder": "Veuillez confirmer l'email, celui-ci ne sera plus modifiable par la suite",
      "invalid_form": "Le formulaire est invalide",
      "confirmation_mail_failed": "La confirmation de l'adresse email a échoué",
      "password_not_same": "La confirmation de nouveau de passe ne correspond pas au nouveau mot de passe"
    },
    "cart_delivery": {
      "title": "Livraison",
      "delivery_title": "Adresse de livraison",
      "delivery_street_label": "Adresse postale",
      "delivery_street_placeholder": "Veuillez saisir l’adresse postale de livraison",
      "delivery_postalcode_label": "Code postal",
      "delivery_postalcode_placeholder": "Veuillez saisir le code postale de livraison",
      "delivery_city_label": "Ville",
      "delivery_city_placeholder": "Veuillez saisir la ville de livraison",
      "delivery_country_label": "Pays",
      "delivery_country_placeholder": "Veuillez saisir le pays de livraison",
      "delivery_next_step_btn": "VALIDER LES INFORMATIONS",
      "delivery_validate_order_btn": "VALIDER LA COMMANDE",
      "create_account": "Créer un compte"
    },
    "cart_checkout": {
      "title": "Paiement",
      "card_title": "Carte bancaire",
      "eficash_title": "Eficash",
      "agency_title": "En agence",
      "mean_btn": "JE CHOISIS CE MODE DE PAIEMENT",
      "mean_selected": "MODE DE PAIEMENT SELECTIONNÉ",
      "order_confirmed": "La commande a été confirmée. Vous pouvez retrouver votre facture dans l'espace Mon Compte.",
      "need_verified": "Pour réaliser un achat, votre compte doit être vérifié.",
      "resend_confirmation_mail": "Renvoyer l'email de confirmation",
      "password_too_short": "Le mot de passe renseigné est trop faible : il doit contenir au moins 6 caractères.",
      "email_already_verified": "Votre email est déjà vérifié",
      "wait": "Veuillez patienter quelques instants avant de réitérer votre demande de confirmation de compte",
      "mail_sended": "Un email vous a été envoyé",
      "error_guest_order_not_allowed": "Le mode invité n'est plus disponible, veuillez vous connecter à votre compte client ou vous créer un compte client pour continuer vos achats",
      "error_order_amount_exceeded": "En ajoutant ce titre à votre panier le montant de commande maximum autorisé en mode invité est dépassé, veuillez vous connecter à votre compte client ou vous créer un compte pour accéder à toutes les fonctionnalités d'achat",
      "error_order_article_document_invalid": "Au moins un document des articles de votre panier n'est pas valide",
      "error_order_article_collect_required": "Au moins un article de votre panier doit être récupéré en agence, mais vous n'avez pas sélectionné d'agence de retrait",
      "preauthorized": "La transaction liée à votre commande sera validée manuellement par le réseau. Vous ne serez pas débité du montant payé et votre commande ne sera pas créditée avant cette validation manuelle.\nSi votre commande n'est pas validée par le réseau dans les 6 jours, aucun montant ne sera débité et votre commande sera annulée."
    },
    "cart_checkout_card": {
      "payment_type_title": "Nombre d'échéances",
      "payment_type_label": "Sélectionnez le nombre d'échéances",
      "payment_type_1x": "paiement en 1x",
      "payment_type_3x": "paiement en 3x",
      "payment_type_10x": "paiement en 10x",
      "payment_type_btn": "Accéder au paiement",
      "alert_payment": "Ce règlement est momentanément indisponible. Veuillez réessayer plus tard.",
      "payment_type_dynamic": "paiement en {0}x",
      "barcode_description_part1": "Pour finaliser votre inscription, cliquez sur le bouton « Accéder au paiement ».",
      "barcode_description_part2": "Votre dossier d’inscription sera validé une fois que le paiement par carte bancaire sera effectué et après traitement des pièces justificatives par les services.",
      "barcode_description_part3": "Nous vous invitons à consulter dans votre espace client, la rubrique « Suivi des demandes » pour vérifier le statut de votre commande.",
      "cash_payment": "Paiement comptant",
      "monthly_payment": "Paiement en {month} mensualité(s)"
    },
    "cart_checkout_eficash": {
      "barcode_title": "Code barre Eficash",
      "barcode_description_part1": "Pour finaliser votre inscription, cliquez sur le bouton « Valider ma demande d’inscription ».",
      "barcode_btn": "Valider ma demande d'inscription",
      "barcode_description_part2": "Vous recevrez un email avec un lien pour télécharger le document EFICASH.",
      "barcode_description_part3": "Présentez ce document auprès de votre bureau de poste muni du montant de votre transaction en espèces.",
      "barcode_description_part4": "Votre dossier d’inscription sera validé une fois le paiement effectué au bureau de Poste et après traitement des pièces justificatives par les services.",
      "barcode_description_part5": "Nous vous invitons à consulter dans votre espace client, la rubrique « Suivi des demandes » pour vérifier le statut de votre commande."
    },
    "cart_checkout_agency": {
      "agency_title": "Paiement en agence",
      "agency_btn": "Valider ma demande d'inscription",
      "agency_description": "Pour finaliser votre inscription veuillez vous rendre en agence muni de votre moyen de paiement.\n\nVotre dossier d’inscription sera validé après versement de votre transaction en agence."
    },
    "stepper": {
      "basket": "Panier",
      "account": "Coordonnées",
      "delivery": "Livraison",
      "checkout": "Paiement"
    },
    "legals": {
      "title": "Mentions légales"
    },
    "school_auth_login": {
      "title": "Inscription au transport scolaire",
      "form_title": "Je possède déjà un compte",
      "login_email_label": "Identifiant",
      "login_email_placeholder": "Email de connexion",
      "login_password_label": "Mot de passe",
      "login_password_placeholder": "Saisir votre mot de passe",
      "forgotten_password": "Mot de passe oublié ?",
      "submit_btn": "Se connecter",
      "register_title": "Première visite ?",
      "register_btn": "S'inscrire",
      "tuto_title": "Je possède déjà un compte",
      "tuto_subtitle": "Comment renouveler un abonnement ?",
      "text_step_1": "Sélectionner l’enfant pour lequel je souhaite renouveler l’inscription",
      "text_step_2": "Vérifier les informations saisies l’année précédente et les mettre à jour si besoin",
      "text_step_3": "Finaliser la transaction",
      "illustration1_alt": "Illustration de deux enfants avec leur cartable",
      "illustration2_alt": "Illustration d'une fille tenant une carte d'identité",
      "illustration3_alt": "Illustration d'une femme avec une carte bancaire",
      "alert_notrecognized": "Votre compte ou mot de passe ne sont pas reconnu.Veuillez réessayer ou vous inscrire depuis le {alert_link_register}.",
      "alert_link_register": "parcours d'inscription"
    },
    "school_auth_register": {
      "title": "Inscription au transport scolaire",
      "subtitle": "Je m'inscris pour la première fois",
      "subsubtitle": "Quelles sont les étapes d'inscription ?",
      "text_step1": "Renseigner les informations scolaires de l'enfant",
      "text_step2": "Choisir l'abonnement souhaité",
      "text_step3": "Renseigner les informations personnelles de l'enfant et ajouter les pièces justificatives demandées",
      "text_step4": "Renseigner vos informations responsable légal et créer votre compte",
      "text_step5": "Finaliser la transaction",
      "next_step_btn": "Poursuivre vers l'inscription",
      "login_btn": "J'ai déjà un compte",
      "illustration1_alt": "Illustration d'une école",
      "illustration2_alt": "Illustration d'une fille portant une carte d'identité",
      "illustration3_alt": "Illustration de deux enfant avec leur cartable",
      "illustration4_alt": "Illustration d'un père jouant avec son fils",
      "illustration5_alt": "Illustration d'une femme avec une carte bancaire",
      "signup": "Je m'inscris"
    },
    "empty_cart": {
      "title": "Votre panier est vide",
      "illustration_alt": "Illustration d'une personne près d'un caddie vide",
      "add_child_btn": "INSCRIRE UN ENFANT"
    },
    "account_menu_sidebar": {
      "profile_btn": "Mon profil responsable légal",
      "invoices_btn": "Mes factures",
      "payment_cards_btn": "Mes cartes de paiement",
      "open_menu": "Ouvrir le menu"
    },
    "account_form": {
      "bloc1_title": "Mon profil responsable légal",
      "picture_label": "Photo de profil",
      "lastname_label": "Nom de famille",
      "lastname_placeholder": "Veuillez saisir votre nom de famille",
      "firstname_label": "Prénom",
      "firstname_placeholder": "Veuillez saisir votre prénom",
      "birthday_label": "Date de naissance",
      "bloc2_title": "Mes informations de contact",
      "address_label": "Adresse postale",
      "address_placeholder": "Veuillez saisir votre adresse",
      "zipcode_label": "Code postal",
      "zipcode_placeholder": "Veuillez saisir votre code postal",
      "city_label": "Ville",
      "city_placeholder": "Veuillez saisir votre ville",
      "country_label": "Pays",
      "country_placeholder": "Veuillez saisir votre pays de résidence",
      "phone_label": "Téléphone",
      "phone_placeholder": "Veuillez saisir votre numéro de téléphone",
      "bloc3_title": "Mes identifiants de connexion",
      "email_label": "Email de connexion",
      "password_label": "Modifier mon mot de passe",
      "current_password_label": "Mot de passe actuel",
      "current_password_placeholder": "Veuillez saisir votre mot de passe actuel",
      "new_password_label": "Nouveau mot de passe",
      "new_password_placeholder": "Veuillez saisir votre nouveau mot de passe",
      "confirm_new_password_label": "Confirmation du nouveau mot de passe",
      "confirm_new_password_placeholder": "Veuillez confirmer votre nouveau mot de passe",
      "submit_btn": "Valider les informations",
      "email_address": "Adresse email",
      "password_min": "Au moins 12 caractères",
      "password_has_uppercase": "Au moins une lettre majuscule",
      "password_has_lowercase": "Au moins une lettre minuscule",
      "password_has_number": "Au moins un chiffre",
      "password_has_special_chars": "Au moins un caractère spécial",
      "birthdate_placeholder": "Veuillez saisir votre date de naissance",
      "firstname_no_numbers": "Aucun chiffre",
      "invalid_password": "Le mot de passe actuel est incorrect",
      "weak_password": "Le nouveau mot de passe très trop faible.",
      "error_password_update": "Une erreur est survenue lors de la mise à jour du mot de passe",
      "error_info": "Une erreur est survenue lors de la mise à jour des informations",
      "password_confirmation_not_match": "La confirmation de nouveau de passe ne correspond pas au nouveau mot de passe",
      "different_password": "Vous devez renseigner un nouveau mot de passe différent de l'actuel",
      "invalid_form": "formulaire invalide",
      "delete_account": "Supprimer mon compte"
    },
    "account_tuto": {
      "tuto1_title": "Pourquoi renseigner “mon profil responsable légal“ ?",
      "tuto1_detail": "Renseigner ces informations permet à votre réseau de transport de vous identifier en tant que responsable légal des enfants pour lesquels vous avez demandé une inscription.",
      "tuto2_title": "Pourquoi renseigner “mes informations de contact“ ?",
      "tuto2_detail": "Renseigner ces informations permet à votre réseau de transport de vous contacter en cas de besoin et de vous tenir informé des événements sur les lignes scolaires.",
      "tuto3_title": "Pourquoi renseigner “vos identifiants de connexion“ ?",
      "tuto3_detail": "Votre email de connexion est non modifiable et vous permet de vous connecter sur le site boutique scolaire de votre réseau. Vous pouvez modifier votre mot de passe à tout moment dans la partie “Modifier mon mot de passe“."
    },
    "account_invoices": {
      "account_title": "Mon compte",
      "invoices_title": "Mes factures",
      "date_label": "Date",
      "amount_label": "Montant",
      "preview_label": "Télécharger",
      "tuto_invoices_title": "A quoi sert la rubrique “Mes factures” ?",
      "filter_icon_alt": "Filtrer les factures par date",
      "preview_icon_alt": "Consulter la facture",
      "file_title_label": "facture_n°_",
      "tuto_invoices_detail": "Vous retrouvez sur cette rubrique les factures liées à vos commandes sur le site.\nLa facture ne s'affiche qu'à partir de la validation de votre demande d'inscription.\nVous pouvez télécharger au format pdf chaque facture."
    },
    "empty_invoices": {
      "noivoice_label": "Aucune facture disponible",
      "tracking_btn": "Accéder au suivi des demandes",
      "illustration_invoice_alt": "Illustration d'une facture"
    },
    "account_payment_cards": {
      "account_title": "Mon compte",
      "credit_card_title": "Mes cartes de paiement",
      "card_number_label": "Numéro de carte",
      "date_label": "Date",
      "delete_label": "Supprimer",
      "tuto_cards_title": "A quoi sert la rubrique “Mes cartes de paiement“ ?",
      "popup_delete_card_title": "Souhaitez-vous vraiment supprimer cette carte de paiement ?",
      "popup_delete_label": "En supprimant cette carte, vous devrez lors de votre prochain paiement soit sélectionner une autre carte enregistrée, soit saisir de nouveau vos informations bancaires",
      "cancel_card_btn": "Annuler",
      "delete_card_btn": "Supprimer",
      "delete_icon_alt": "Supprimer la carte de paiement",
      "tuto_cards_detail": "Vous retrouvez sur cette rubrique les cartes que vous avez enregistrées lors de vos précédents paiements.\nEn supprimant vos cartes vous devrez ressaisir vos informations bancaires lors de vos prochaines transactions.",
      "error_delete": "Erreur lors de la suppression de la carte"
    },
    "empty_payment_cards": {
      "nocards_label": "Aucune carte de paiement disponible",
      "shop_btn": "Renouveler un abonnement",
      "illustration_payment_cards_alt": "Illustration de cartes de paiement"
    },
    "tracking_inscriptions": {
      "tracking_inscriptions_title": "Suivi des demandes",
      "document_refused_alert": "Des pièces justificatives ont été refusées suite à votre demande d'inscription.Nous vous invitons à actualiser vos pièces justificatives en accédant au détail de votre demande.Attention vous avez 6 jours maximum à compter de la date de votre demande pour actualiser vos documents, sans cela la demande sera automatiquement annulée.",
      "request_validated_alert": "Une ou plusieurs de vos demandes ont été acceptée.\nLe montant de votre commande sera facturée, vous pouvez retrouver votre facture dans “Mon compte“. ",
      "application_number_label": "Demande N° {0}",
      "status_accepted_label": "demande acceptée",
      "status_refused_label": "demande à compléter",
      "status_pending_label": "demande en attente de traitement",
      "detail_btn": "voir le detail",
      "inscription_refused": "dossier refusé",
      "inscription_accepted": "dossier accepté",
      "inscription_pending": "dossier en attente",
      "tuto_processing_time_title": "En combien de temps est traitée ma demande d'inscription scolaire ?",
      "tuto_inscription_refused_title": "Pourquoi ma demande est-elle à compléter ?",
      "tuto_inscription_refused_label": "Votre demande peut être à compléter car une des pièces-justificatives fournies ne correspondait pas à la demande.\nConsultez le détail de votre demande en cliquant sur “voir le détail“.",
      "tuto_processing_time_label": "Votre réseau de transport scolaire à 6 jours pour valider votre demande.\nAprès cette période de vérification de votre dossier, votre titre scolaire vous sera envoyé selon les modalités choisies lors de votre commande.\n"
    },
    "empty_tracking_inscription": {
      "tracking_request_title": "Suivi des demandes",
      "norequest_label": "Aucune demande n'est en cours de traitement",
      "shop_btn": "Renouveler une inscription",
      "illustration_alt": "Illustration d'un homme debout près d'un gros symbole plus"
    },
    "tracking_detail": {
      "tracking_detail_title": "Détail de la demande",
      "application_number_label": "Demande n° {0}",
      "status_refused_label": "Demande à compléter",
      "status_accepted_label": "Demande acceptée",
      "inscription_accepted_label": "dossier accepté",
      "inscription_refused_label": "dossier refusé",
      "document_label": "pièce justificative",
      "status_label": "statut",
      "preview_label": "consulter",
      "update_label": "mettre à jour",
      "document_accepted": "pièce acceptée",
      "document_refused": "pièce refusée",
      "preview_icon_alt": "Afficher la pièce justificative",
      "update_icon_alt": "Mettre à jour la pièce refusée",
      "update_icon_notactive_alt": "pièce déjà validée",
      "tuto_update_title": "Comment mettre à jour ma demande ?",
      "tuto_update_detail_label": "Mettez à jour votre pièce-justificative refusée et cliquez sur “Envoyer la mise à jour“ pour alerter votre réseau de transport scolaire de votre nouvelle pièce justificative.",
      "send_update_btn": "Envoyer la mise à jour",
      "status_pending_label": "Demande en attente de traitement",
      "inscription_pending": "dossier en attente",
      "document_pending": "pièce en attente de traitement",
      "error_invoices": "Erreur lors de la récupération des factures",
      "go_to_student_subscribers": "Accéder aux élèves abonnés",
      "finish_shopping": "Terminer mes achats"
    },
    "popup_payment": {
      "validated_title": "Commande confirmée",
      "validated_label": "La commande a été confirmée.Vous allez être redirigé vers la page de suivi des demandes",
      "error_title": "Paiement échoué",
      "error_label": "Le paiement a été refusé.Vous allez être redirigé vers le panier.",
      "cancelled_title": "Paiement annulé",
      "cancelled_label": "Le paiement a été annulé.Vous allez être redirigé vers le panier.",
      "btn_tracking": "Accéder au suivi",
      "btn_cart": "Accéder au panier",
      "btn_close": "Fermer"
    },
    "home_profiles": {
      "subscribed_children_title": "Enfants abonnés",
      "edit_profile_btn": "éditer le profil",
      "renew_inscription_btn": "Renouveler l'abonnement",
      "duplicata_btn": "Demander un duplicata",
      "add_btn": "Ajouter un enfant",
      "tuto_renew_title": "Comment renouveler l'abonnement ?",
      "tuto_renew_txt": "Cliquez sur le bouton “Renouveler l'abonnement” puis vérifiez les informations saisies lors de votre précédent achat et actualisez les données pour définir le nouvel abonnement de l'enfant.",
      "tuto_add_title": "Comment ajouter un enfant ?",
      "tuto_add_txt": "Cliquez sur le bouton “Ajouter un enfant”, poursuivez les étapes d'inscription. Après finalisation de votre commande l'enfant inscrit apparaîtra dans la liste des enfants abonnés.",
      "badge_label": "inscription ajoutée au panier",
      "student_subscribers": "Élèves abonnés"
    },
    "profile": {
      "profile_title": "Profil enfant",
      "personnal_info_title": "Informations personnelles",
      "picture_label": "Photo de profil de l'abonnement",
      "edit_picture_btn": "Modifier la photo",
      "lastname_label": "Nom de famille",
      "kinship_label": "Lien de parenté du responsable légal",
      "school_info_title": "Informations de scolarité",
      "dwelling_label": "Lieu d'habitation",
      "school_label": "Etablissement scolaire",
      "cafeteria_status_label": "Régime",
      "bus_line_label": "Ligne de bus",
      "contact_info_title": "Informations de contact",
      "email_label": "Email",
      "phone_label": "Téléphone",
      "tuto_info_title": "A quoi correspondent ces informations ?",
      "firstname_label": "Prénom",
      "tuto_info_txt": "Les informations que vous retrouvez sur cette page correspondent aux éléments saisi lors de votre dernière demande d'inscription.\nVous pouvez modifier vos informations personnelles et de contact, les informations de scolarité pourront être modifiées lors de votre prochaine inscription.",
      "exlpain_temporary_photo": "Photo temporaire, pour valider la modification, cliquez sur \\\"Valider les informations\\\""
    },
    "auth_reset": {
      "title": "Mot de passe oublié",
      "label": "Email de connexion",
      "placeholder": "Entrer l'email de connexion",
      "cancel": "Annuler",
      "submit": "Réinitialiser",
      "alert_error1": "Email invalide",
      "alert_error2": "Erreur lors de la réinitialisation. Veuillez rééssayer.",
      "alert_success": "Si un compte existe avec cette adresse email, un email de réinitialisation a été envoyé.",
      "error_message": "Une erreur s'est produite lors de la réinitialisation de votre mot de passe. Il est possible l'adresse renseignée ne soit pas au bon format. Si le problème persiste, veuillez contacter le support."
    },
    "duplicata": {
      "title": "Demande de duplicata",
      "label_personal_info": "Informations personnelles",
      "label_picture": "Photo de profil",
      "change_picture_btn": "Modifier la photo",
      "label_last_name": "Nom de famille",
      "label_first_name": "Prénom",
      "label_reasons_request": "Motif de la demande",
      "label_reason_selector": "Sélectionner le motif de votre demande",
      "label_stolen_pass": "La carte a été dérobée et je dispose d'un dépôt de plainte",
      "label_lost_pass": "La carte a été perdue",
      "label_documents": "Pièce(s)-justificative(s)",
      "placeholder_upload": "Télécharger le document",
      "back_btn": "Retour",
      "title_tuto_duplicata": "Pourquoi disposer d'un dépôt de plainte ?",
      "text_tuto_duplicata": "Si vous disposez d'un justificatif de dépôt de plainte suite au vol de votre carte de transport, la demande de duplicata sera pris en charge par votre réseau.",
      "error_image": "Erreur lors de la mise à jour de la photo de profil",
      "error_update_profil": "Erreur lors de la mise à jour du profil",
      "no_profil_selected": "Aucun profil n'a été sélectionné",
      "cannot_duplicate": "Le profil sélectionné ne peut pas faire de demande de duplicata"
    },
    "clear": {
      "cache_clear": "Nous vidons votre cache",
      "infinit_loader": "La page charge indéfiniment ?",
      "back_to_shop": "Je retourne sur la boutique"
    },
    "unverified": {
      "need_confirm_account": "Votre compte doit être vérifié",
      "security_verified_account": "Pour plus de sécurité, votre compte doit être vérifié.",
      "resend_mail": "Renvoyer l'email de confirmation",
      "close": "Fermer",
      "email_has_been_send": "Un email vous a été envoyé",
      "email_with_link": "Un email avec lien de confirmation a été envoyé à cette adresse email :",
      "can_buy_with_link": "Vous pourrez acheter de nouveau des titres de transport en cliquant sur le lien reçu par email."
    },
    "verification": {
      "please_confirm_signup": "Veuillez confirmer votre inscription",
      "received_email": "Vous avez reçu un email à l'adresse",
      "please_confirm": "Veuillez cliquer sur le lien de confirmation présent dans l'email afin de valider votre inscription.",
      "please_confirm_explain": "La vérification du compte est nécessaire pour acheter votre titre de transport.",
      "go_to_mail": "Accéder à mes emails",
      "back_to_cart": "Retourner au panier"
    },
    "help_sidebar": {
      "blocked": "Je suis bloqué",
      "schedule_appointment": "Je programme un rendez-vous",
      "problem_description": "Si vous rencontrez toujours des difficultés techniques même en réinitialisant votre session, vous pouvez programmer un rendez-vous avec nous en cliquant sur le bouton ci-dessous.",
      "problem_title": "Les problèmes persistent",
      "reset_session": "Je réinitialise ma session",
      "difficulty_description_1": "Vous pouvez cliquer sur le bouton ci-dessous pour recommencer la procédure d'inscription.",
      "difficulty_description_2": "Ce bouton réinitialisera votre session et vous permettra de recommencer la procédure."
    },
    "disabled_profile": {
      "title": "Désactivation du compte de {firstName}",
      "description": "Etes-vous sûr de voir désactiver le profil de {lastName} {firstName} ?",
      "warning": "Si vous souhaitez renouveler l'abonnement de votre enfant, vous devrez alors le réinscrire",
      "disabled": "Désactiver",
      "success_message": "Le compte est désactivé"
    },
    "maintenance": {
      "title": "Site actuellement en maintenance",
      "description": "Merci de patienter pendant que nous effectuons des travaux de maintenance pour améliorer votre expérience utilisateur. Nous vous remercions de votre compréhension",
      "no_estimated_date": "Veuillez revenir plus tard",
      "estimated_date": "Date de fin de maintenance estimée : {date}",
      "refresh": "Rafraichir"
    },
    "account_delete": {
      "warning": "Veuillez lire attentivement les informations sur la suppression de compte.",
      "delete_info": "La suppression de votre compte entraînera la suppression définitive des données suivantes sur l'ensemble des applications et boutiques de la société airweb.",
      "data_to_delete": "Liste des données à supprimer :",
      "personnal_data": "Vos données personnelles",
      "delivery_address": "Vos adresses de livraison",
      "payment_method": "Vos modes de paiement",
      "tickets": "Votre portefeuille de titres",
      "networks": "Vos comptes liés sur les réseaux de transport suivants :",
      "error_data": "Une erreur s'est produite lors de la récupération des informations",
      "delay": "Vous possédez un délai de rétractation de 30 jours. Sans action de votre part, le compte sera définitivement supprimé.",
      "account_details": "Détail des comptes liés",
      "no_ticket": "Ce profil n'a pas de titre actif",
      "delete": "Supprimer",
      "tacite_message": "Impossible de supprimer votre compte car vous avez un ou des abonnements tacites en cours de validité. Veuillez annuler la reconduction tacite de votre ou de vos abonnements avant de supprimer votre compte.",
      "error_delete": "Une erreur s'est produite lors de la suppression de votre compte"
    },
    "delete_success": {
      "title": "Enregistrement de votre demande",
      "submission_save": "Votre demande a bien été enregistrée.",
      "description": "Vous allez recevoir d'ici quelques minutes un email confirmant la prise en compte de votre demande de suppression.\n\nSans action de votre part sous 30 jours, votre compte sera définitivement supprimé sans possibilité de récupérer vos données.\n\nEn cliquant sur “J'ai compris”, vous allez être déconnecté de votre compte.",
      "button": "J'ai compris",
      "redirect": "Vous allez être redirigé dans quelques instants"
    },
    "account_reactivation_modal": {
      "text": "La réactivation de votre compte annule votre suppression de compte.\\nConfirmez-vous vouloir réactiver votre compte ?"
    }
  },
  "labeldate": {},
  "otherslabels": {
    "fr": "Français",
    "en": "Anglais",
    "close_alert_text": "Fermer l'alerte",
    "inline_upload_description_btn_alt": "Voir plus d'informations",
    "inline_upload_delete_btn_alt": "Supprimer le fichier",
    "upload_img_alt": "Fichier déjà mis en ligne",
    "price_selected": "Tarif sélectionné",
    "btn_price": "Je choisis ce titre",
    "price_card_illustration": "Illustration d'enfant en train de jouer",
    "common_loading": "Chargement en cours...",
    "common_error": "Une erreur est survenue",
    "upload_profile_picture": "Ajouter la photo",
    "uploaded_profile_picture": "Modifier la photo",
    "btn_next_step": "Étape suivante",
    "btn_previous_step": "Étape précédente",
    "button_tc": "Accepter les CGV",
    "popup_tc": "Conditions générales de vente",
    "signup_alert_name_without_vowel": "Au moins une voyelle",
    "signup_alert_invalid_name": "Aucun caractères spéciaux ou chiffres",
    "signup_alert_same_name_firstname": "Les noms et prénoms ne doivent pas être identique",
    "signup_alert_name_characteres_min": "Au moins 3 caractères",
    "signup_alert_invalid_phonenumber": "Au moins 10 chiffres et commencer par un 0",
    "signup_alert_invalid_email": "Doit contenir le caractère {'@'}",
    "signup_alert_invalid_password": "Au moins 6 caractères",
    "signup_alert_invalid_password_confirmation": "Mot de passe identique",
    "signup_alert_firstname_characteres_min": "Au moins 3 caractères",
    "btn_validate": "Valider les informations",
    "popup_session_timeout": "Votre session a expirée, veuillez vous reconnecter.",
    "popup_session_timeout_button": "Je me connecte",
    "alert_email_alreadyinuse": "Veuillez vous connecter depuis la {alert_link_loginpage} et à utiliser la fonctionnalité de mot de passe oublié si vous avez perdu ce dernier.",
    "alert_link_loginpage": "page de connexion",
    "popup_session_timeout_title": "Session expirée",
    "alert_update_account_info_success": "Informations mises à jour avec succès",
    "alert_update_account_picture_success": "Photo de profil mise à jour avec succès",
    "alert_update_account_password_success": "Mot de passe mis à jour avec succès",
    "alert_update_account_info_error": "Erreur lors de la mise à jour des informations",
    "alert_update_account_picture_error": "Erreur lors de la mise à jour de la photo",
    "alert_update_account_password_error": "Erreur lors de la mise à jour du mot de passe",
    "input_password_toggle_btn": "Afficher ou cacher le mot de passe",
    "documents_type_title": "Format des pièces justificatives",
    "documents_type_content": "Les pièces doivent être au format image (png ou jpg) ou au format pdf",
    "signup_alert_invalid_email_confirmation": "Email identique",
    "line_more_info": "Cliquez ici pour le détail des lignes",
    "child_older_than_3": "J’atteste sur l’honneur que l’élève est âgé de plus de 3 ans révolus",
    "error_details": "Détails de l'erreur",
    "warning": "Attention !",
    "image_type_title": "Format de la photo de l'élève",
    "image_type_content": "La photo d’identité ne doit pas dépasser un poids de 20 Mo.",
    "drop_file": "Déposer votre fichier ici",
    "drop_photo": "Déposer votre photo ici",
    "cancel": "Annuler",
    "wrong_date": "La date saisie est incorrecte",
    "date_limit": "La date dépasse la limite maximal de {date}",
    "day": "jour",
    "month": "mois",
    "year": "anneé",
    "field_required": "Champ requis",
    "close": "Fermer",
    "continue": "Continuer"
  },
  "popup_mentions": {},
  "country": {}
}