import { default as deleteGnDOesUGIEMeta } from "/app/pages/account/delete.vue?macro=true";
import { default as indexQWKFaMHH2OMeta } from "/app/pages/account/index.vue?macro=true";
import { default as invoicestmq8neHAodMeta } from "/app/pages/account/invoices.vue?macro=true";
import { default as payment_45cardsRnR9jGJ0cbMeta } from "/app/pages/account/payment-cards.vue?macro=true";
import { default as accountIWnKuvcAuMMeta } from "/app/pages/account.vue?macro=true";
import { default as indexaVPDnhf1OvMeta } from "/app/pages/auth/index.vue?macro=true";
import { default as accountgZwXH2t970Meta } from "/app/pages/cart/account.vue?macro=true";
import { default as checkout4YA79jVi1xMeta } from "/app/pages/cart/checkout.vue?macro=true";
import { default as deliverywEtTBtwXGCMeta } from "/app/pages/cart/delivery.vue?macro=true";
import { default as indexAacQV8oPDMMeta } from "/app/pages/cart/index.vue?macro=true";
import { default as cart9SktLKPpbzMeta } from "/app/pages/cart.vue?macro=true";
import { default as clear0ItD2BFdyuMeta } from "/app/pages/clear.vue?macro=true";
import { default as infoKQcaUvIdSzMeta } from "/app/pages/delete/info.vue?macro=true";
import { default as successM10n7WF9beMeta } from "/app/pages/delete/success.vue?macro=true";
import { default as _91id_93pAQXQg0sNXMeta } from "/app/pages/duplicate/[id].vue?macro=true";
import { default as productsDYGVPbswcZMeta } from "/app/pages/duplicate/products.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as legalsl9w9Er0CCLMeta } from "/app/pages/legals.vue?macro=true";
import { default as maintenanceM1L3mPagESMeta } from "/app/pages/maintenance.vue?macro=true";
import { default as _91id_93m5hwe6XdulMeta } from "/app/pages/orders/[id].vue?macro=true";
import { default as indexG1Ehu1sqkUMeta } from "/app/pages/orders/index.vue?macro=true";
import { default as _91id_930vg4Yd6NyQMeta } from "/app/pages/profiles/[id].vue?macro=true";
import { default as indexBqiuZpk0bsMeta } from "/app/pages/profiles/index.vue?macro=true";
import { default as indexhOBcdtdQ4pMeta } from "/app/pages/register/index.vue?macro=true";
import { default as personalpG2OpCiAMqMeta } from "/app/pages/register/personal.vue?macro=true";
import { default as products0OgH2noNHPMeta } from "/app/pages/register/products.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as indexwfiOBUrUy9Meta } from "/app/pages/renew/index.vue?macro=true";
import { default as personaloVE68LnQpoMeta } from "/app/pages/renew/personal.vue?macro=true";
import { default as products2MpwGFqUW9Meta } from "/app/pages/renew/products.vue?macro=true";
import { default as renewoZHCwPTQaYMeta } from "/app/pages/renew.vue?macro=true";
import { default as unverifiedCxsaUjVRgbMeta } from "/app/pages/unverified.vue?macro=true";
import { default as verification4hxpuJdPABMeta } from "/app/pages/verification.vue?macro=true";
export default [
  {
    path: accountIWnKuvcAuMMeta?.path ?? "/account",
    children: [
  {
    name: deleteGnDOesUGIEMeta?.name ?? "account-delete___fr",
    path: deleteGnDOesUGIEMeta?.path ?? "delete",
    meta: deleteGnDOesUGIEMeta || {},
    alias: deleteGnDOesUGIEMeta?.alias || [],
    redirect: deleteGnDOesUGIEMeta?.redirect || undefined,
    component: () => import("/app/pages/account/delete.vue").then(m => m.default || m)
  },
  {
    name: indexQWKFaMHH2OMeta?.name ?? "account___fr",
    path: indexQWKFaMHH2OMeta?.path ?? "",
    meta: indexQWKFaMHH2OMeta || {},
    alias: indexQWKFaMHH2OMeta?.alias || [],
    redirect: indexQWKFaMHH2OMeta?.redirect || undefined,
    component: () => import("/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: invoicestmq8neHAodMeta?.name ?? "account-invoices___fr",
    path: invoicestmq8neHAodMeta?.path ?? "invoices",
    meta: invoicestmq8neHAodMeta || {},
    alias: invoicestmq8neHAodMeta?.alias || [],
    redirect: invoicestmq8neHAodMeta?.redirect || undefined,
    component: () => import("/app/pages/account/invoices.vue").then(m => m.default || m)
  },
  {
    name: payment_45cardsRnR9jGJ0cbMeta?.name ?? "account-payment-cards___fr",
    path: payment_45cardsRnR9jGJ0cbMeta?.path ?? "payment-cards",
    meta: payment_45cardsRnR9jGJ0cbMeta || {},
    alias: payment_45cardsRnR9jGJ0cbMeta?.alias || [],
    redirect: payment_45cardsRnR9jGJ0cbMeta?.redirect || undefined,
    component: () => import("/app/pages/account/payment-cards.vue").then(m => m.default || m)
  }
],
    name: accountIWnKuvcAuMMeta?.name ?? undefined,
    meta: accountIWnKuvcAuMMeta || {},
    alias: accountIWnKuvcAuMMeta?.alias || [],
    redirect: accountIWnKuvcAuMMeta?.redirect || undefined,
    component: () => import("/app/pages/account.vue").then(m => m.default || m)
  },
  {
    path: accountIWnKuvcAuMMeta?.path ?? "/en/account",
    children: [
  {
    name: deleteGnDOesUGIEMeta?.name ?? "account-delete___en",
    path: deleteGnDOesUGIEMeta?.path ?? "delete",
    meta: deleteGnDOesUGIEMeta || {},
    alias: deleteGnDOesUGIEMeta?.alias || [],
    redirect: deleteGnDOesUGIEMeta?.redirect || undefined,
    component: () => import("/app/pages/account/delete.vue").then(m => m.default || m)
  },
  {
    name: indexQWKFaMHH2OMeta?.name ?? "account___en",
    path: indexQWKFaMHH2OMeta?.path ?? "",
    meta: indexQWKFaMHH2OMeta || {},
    alias: indexQWKFaMHH2OMeta?.alias || [],
    redirect: indexQWKFaMHH2OMeta?.redirect || undefined,
    component: () => import("/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: invoicestmq8neHAodMeta?.name ?? "account-invoices___en",
    path: invoicestmq8neHAodMeta?.path ?? "invoices",
    meta: invoicestmq8neHAodMeta || {},
    alias: invoicestmq8neHAodMeta?.alias || [],
    redirect: invoicestmq8neHAodMeta?.redirect || undefined,
    component: () => import("/app/pages/account/invoices.vue").then(m => m.default || m)
  },
  {
    name: payment_45cardsRnR9jGJ0cbMeta?.name ?? "account-payment-cards___en",
    path: payment_45cardsRnR9jGJ0cbMeta?.path ?? "payment-cards",
    meta: payment_45cardsRnR9jGJ0cbMeta || {},
    alias: payment_45cardsRnR9jGJ0cbMeta?.alias || [],
    redirect: payment_45cardsRnR9jGJ0cbMeta?.redirect || undefined,
    component: () => import("/app/pages/account/payment-cards.vue").then(m => m.default || m)
  }
],
    name: accountIWnKuvcAuMMeta?.name ?? undefined,
    meta: accountIWnKuvcAuMMeta || {},
    alias: accountIWnKuvcAuMMeta?.alias || [],
    redirect: accountIWnKuvcAuMMeta?.redirect || undefined,
    component: () => import("/app/pages/account.vue").then(m => m.default || m)
  },
  {
    name: indexaVPDnhf1OvMeta?.name ?? "auth___fr",
    path: indexaVPDnhf1OvMeta?.path ?? "/auth",
    meta: indexaVPDnhf1OvMeta || {},
    alias: indexaVPDnhf1OvMeta?.alias || [],
    redirect: indexaVPDnhf1OvMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexaVPDnhf1OvMeta?.name ?? "auth___en",
    path: indexaVPDnhf1OvMeta?.path ?? "/en/auth",
    meta: indexaVPDnhf1OvMeta || {},
    alias: indexaVPDnhf1OvMeta?.alias || [],
    redirect: indexaVPDnhf1OvMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    path: cart9SktLKPpbzMeta?.path ?? "/cart",
    children: [
  {
    name: accountgZwXH2t970Meta?.name ?? "cart-account___fr",
    path: accountgZwXH2t970Meta?.path ?? "account",
    meta: accountgZwXH2t970Meta || {},
    alias: accountgZwXH2t970Meta?.alias || [],
    redirect: accountgZwXH2t970Meta?.redirect || undefined,
    component: () => import("/app/pages/cart/account.vue").then(m => m.default || m)
  },
  {
    name: checkout4YA79jVi1xMeta?.name ?? "cart-checkout___fr",
    path: checkout4YA79jVi1xMeta?.path ?? "checkout",
    meta: checkout4YA79jVi1xMeta || {},
    alias: checkout4YA79jVi1xMeta?.alias || [],
    redirect: checkout4YA79jVi1xMeta?.redirect || undefined,
    component: () => import("/app/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: deliverywEtTBtwXGCMeta?.name ?? "cart-delivery___fr",
    path: deliverywEtTBtwXGCMeta?.path ?? "delivery",
    meta: deliverywEtTBtwXGCMeta || {},
    alias: deliverywEtTBtwXGCMeta?.alias || [],
    redirect: deliverywEtTBtwXGCMeta?.redirect || undefined,
    component: () => import("/app/pages/cart/delivery.vue").then(m => m.default || m)
  },
  {
    name: indexAacQV8oPDMMeta?.name ?? "cart___fr",
    path: indexAacQV8oPDMMeta?.path ?? "",
    meta: indexAacQV8oPDMMeta || {},
    alias: indexAacQV8oPDMMeta?.alias || [],
    redirect: indexAacQV8oPDMMeta?.redirect || undefined,
    component: () => import("/app/pages/cart/index.vue").then(m => m.default || m)
  }
],
    name: cart9SktLKPpbzMeta?.name ?? undefined,
    meta: cart9SktLKPpbzMeta || {},
    alias: cart9SktLKPpbzMeta?.alias || [],
    redirect: cart9SktLKPpbzMeta?.redirect || undefined,
    component: () => import("/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    path: cart9SktLKPpbzMeta?.path ?? "/en/cart",
    children: [
  {
    name: accountgZwXH2t970Meta?.name ?? "cart-account___en",
    path: accountgZwXH2t970Meta?.path ?? "account",
    meta: accountgZwXH2t970Meta || {},
    alias: accountgZwXH2t970Meta?.alias || [],
    redirect: accountgZwXH2t970Meta?.redirect || undefined,
    component: () => import("/app/pages/cart/account.vue").then(m => m.default || m)
  },
  {
    name: checkout4YA79jVi1xMeta?.name ?? "cart-checkout___en",
    path: checkout4YA79jVi1xMeta?.path ?? "checkout",
    meta: checkout4YA79jVi1xMeta || {},
    alias: checkout4YA79jVi1xMeta?.alias || [],
    redirect: checkout4YA79jVi1xMeta?.redirect || undefined,
    component: () => import("/app/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: deliverywEtTBtwXGCMeta?.name ?? "cart-delivery___en",
    path: deliverywEtTBtwXGCMeta?.path ?? "delivery",
    meta: deliverywEtTBtwXGCMeta || {},
    alias: deliverywEtTBtwXGCMeta?.alias || [],
    redirect: deliverywEtTBtwXGCMeta?.redirect || undefined,
    component: () => import("/app/pages/cart/delivery.vue").then(m => m.default || m)
  },
  {
    name: indexAacQV8oPDMMeta?.name ?? "cart___en",
    path: indexAacQV8oPDMMeta?.path ?? "",
    meta: indexAacQV8oPDMMeta || {},
    alias: indexAacQV8oPDMMeta?.alias || [],
    redirect: indexAacQV8oPDMMeta?.redirect || undefined,
    component: () => import("/app/pages/cart/index.vue").then(m => m.default || m)
  }
],
    name: cart9SktLKPpbzMeta?.name ?? undefined,
    meta: cart9SktLKPpbzMeta || {},
    alias: cart9SktLKPpbzMeta?.alias || [],
    redirect: cart9SktLKPpbzMeta?.redirect || undefined,
    component: () => import("/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: clear0ItD2BFdyuMeta?.name ?? "clear___fr",
    path: clear0ItD2BFdyuMeta?.path ?? "/clear",
    meta: clear0ItD2BFdyuMeta || {},
    alias: clear0ItD2BFdyuMeta?.alias || [],
    redirect: clear0ItD2BFdyuMeta?.redirect || undefined,
    component: () => import("/app/pages/clear.vue").then(m => m.default || m)
  },
  {
    name: clear0ItD2BFdyuMeta?.name ?? "clear___en",
    path: clear0ItD2BFdyuMeta?.path ?? "/en/clear",
    meta: clear0ItD2BFdyuMeta || {},
    alias: clear0ItD2BFdyuMeta?.alias || [],
    redirect: clear0ItD2BFdyuMeta?.redirect || undefined,
    component: () => import("/app/pages/clear.vue").then(m => m.default || m)
  },
  {
    name: infoKQcaUvIdSzMeta?.name ?? "delete-info___fr",
    path: infoKQcaUvIdSzMeta?.path ?? "/delete/info",
    meta: infoKQcaUvIdSzMeta || {},
    alias: infoKQcaUvIdSzMeta?.alias || [],
    redirect: infoKQcaUvIdSzMeta?.redirect || undefined,
    component: () => import("/app/pages/delete/info.vue").then(m => m.default || m)
  },
  {
    name: infoKQcaUvIdSzMeta?.name ?? "delete-info___en",
    path: infoKQcaUvIdSzMeta?.path ?? "/en/delete/info",
    meta: infoKQcaUvIdSzMeta || {},
    alias: infoKQcaUvIdSzMeta?.alias || [],
    redirect: infoKQcaUvIdSzMeta?.redirect || undefined,
    component: () => import("/app/pages/delete/info.vue").then(m => m.default || m)
  },
  {
    name: successM10n7WF9beMeta?.name ?? "delete-success___fr",
    path: successM10n7WF9beMeta?.path ?? "/delete/success",
    meta: successM10n7WF9beMeta || {},
    alias: successM10n7WF9beMeta?.alias || [],
    redirect: successM10n7WF9beMeta?.redirect || undefined,
    component: () => import("/app/pages/delete/success.vue").then(m => m.default || m)
  },
  {
    name: successM10n7WF9beMeta?.name ?? "delete-success___en",
    path: successM10n7WF9beMeta?.path ?? "/en/delete/success",
    meta: successM10n7WF9beMeta || {},
    alias: successM10n7WF9beMeta?.alias || [],
    redirect: successM10n7WF9beMeta?.redirect || undefined,
    component: () => import("/app/pages/delete/success.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pAQXQg0sNXMeta?.name ?? "duplicate-id___fr",
    path: _91id_93pAQXQg0sNXMeta?.path ?? "/duplicate/:id()",
    meta: _91id_93pAQXQg0sNXMeta || {},
    alias: _91id_93pAQXQg0sNXMeta?.alias || [],
    redirect: _91id_93pAQXQg0sNXMeta?.redirect || undefined,
    component: () => import("/app/pages/duplicate/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93pAQXQg0sNXMeta?.name ?? "duplicate-id___en",
    path: _91id_93pAQXQg0sNXMeta?.path ?? "/en/duplicate/:id()",
    meta: _91id_93pAQXQg0sNXMeta || {},
    alias: _91id_93pAQXQg0sNXMeta?.alias || [],
    redirect: _91id_93pAQXQg0sNXMeta?.redirect || undefined,
    component: () => import("/app/pages/duplicate/[id].vue").then(m => m.default || m)
  },
  {
    name: productsDYGVPbswcZMeta?.name ?? "duplicate-products___fr",
    path: productsDYGVPbswcZMeta?.path ?? "/duplicate/products",
    meta: productsDYGVPbswcZMeta || {},
    alias: productsDYGVPbswcZMeta?.alias || [],
    redirect: productsDYGVPbswcZMeta?.redirect || undefined,
    component: () => import("/app/pages/duplicate/products.vue").then(m => m.default || m)
  },
  {
    name: productsDYGVPbswcZMeta?.name ?? "duplicate-products___en",
    path: productsDYGVPbswcZMeta?.path ?? "/en/duplicate/products",
    meta: productsDYGVPbswcZMeta || {},
    alias: productsDYGVPbswcZMeta?.alias || [],
    redirect: productsDYGVPbswcZMeta?.redirect || undefined,
    component: () => import("/app/pages/duplicate/products.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___fr",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/en",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legalsl9w9Er0CCLMeta?.name ?? "legals___fr",
    path: legalsl9w9Er0CCLMeta?.path ?? "/legals",
    meta: legalsl9w9Er0CCLMeta || {},
    alias: legalsl9w9Er0CCLMeta?.alias || [],
    redirect: legalsl9w9Er0CCLMeta?.redirect || undefined,
    component: () => import("/app/pages/legals.vue").then(m => m.default || m)
  },
  {
    name: legalsl9w9Er0CCLMeta?.name ?? "legals___en",
    path: legalsl9w9Er0CCLMeta?.path ?? "/en/legals",
    meta: legalsl9w9Er0CCLMeta || {},
    alias: legalsl9w9Er0CCLMeta?.alias || [],
    redirect: legalsl9w9Er0CCLMeta?.redirect || undefined,
    component: () => import("/app/pages/legals.vue").then(m => m.default || m)
  },
  {
    name: maintenanceM1L3mPagESMeta?.name ?? "maintenance___fr",
    path: maintenanceM1L3mPagESMeta?.path ?? "/maintenance",
    meta: maintenanceM1L3mPagESMeta || {},
    alias: maintenanceM1L3mPagESMeta?.alias || [],
    redirect: maintenanceM1L3mPagESMeta?.redirect || undefined,
    component: () => import("/app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceM1L3mPagESMeta?.name ?? "maintenance___en",
    path: maintenanceM1L3mPagESMeta?.path ?? "/en/maintenance",
    meta: maintenanceM1L3mPagESMeta || {},
    alias: maintenanceM1L3mPagESMeta?.alias || [],
    redirect: maintenanceM1L3mPagESMeta?.redirect || undefined,
    component: () => import("/app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91id_93m5hwe6XdulMeta?.name ?? "orders-id___fr",
    path: _91id_93m5hwe6XdulMeta?.path ?? "/orders/:id()",
    meta: _91id_93m5hwe6XdulMeta || {},
    alias: _91id_93m5hwe6XdulMeta?.alias || [],
    redirect: _91id_93m5hwe6XdulMeta?.redirect || undefined,
    component: () => import("/app/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93m5hwe6XdulMeta?.name ?? "orders-id___en",
    path: _91id_93m5hwe6XdulMeta?.path ?? "/en/orders/:id()",
    meta: _91id_93m5hwe6XdulMeta || {},
    alias: _91id_93m5hwe6XdulMeta?.alias || [],
    redirect: _91id_93m5hwe6XdulMeta?.redirect || undefined,
    component: () => import("/app/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexG1Ehu1sqkUMeta?.name ?? "orders___fr",
    path: indexG1Ehu1sqkUMeta?.path ?? "/orders",
    meta: indexG1Ehu1sqkUMeta || {},
    alias: indexG1Ehu1sqkUMeta?.alias || [],
    redirect: indexG1Ehu1sqkUMeta?.redirect || undefined,
    component: () => import("/app/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1Ehu1sqkUMeta?.name ?? "orders___en",
    path: indexG1Ehu1sqkUMeta?.path ?? "/en/orders",
    meta: indexG1Ehu1sqkUMeta || {},
    alias: indexG1Ehu1sqkUMeta?.alias || [],
    redirect: indexG1Ehu1sqkUMeta?.redirect || undefined,
    component: () => import("/app/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_930vg4Yd6NyQMeta?.name ?? "profiles-id___fr",
    path: _91id_930vg4Yd6NyQMeta?.path ?? "/profiles/:id()",
    meta: _91id_930vg4Yd6NyQMeta || {},
    alias: _91id_930vg4Yd6NyQMeta?.alias || [],
    redirect: _91id_930vg4Yd6NyQMeta?.redirect || undefined,
    component: () => import("/app/pages/profiles/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_930vg4Yd6NyQMeta?.name ?? "profiles-id___en",
    path: _91id_930vg4Yd6NyQMeta?.path ?? "/en/profiles/:id()",
    meta: _91id_930vg4Yd6NyQMeta || {},
    alias: _91id_930vg4Yd6NyQMeta?.alias || [],
    redirect: _91id_930vg4Yd6NyQMeta?.redirect || undefined,
    component: () => import("/app/pages/profiles/[id].vue").then(m => m.default || m)
  },
  {
    name: indexBqiuZpk0bsMeta?.name ?? "profiles___fr",
    path: indexBqiuZpk0bsMeta?.path ?? "/profiles",
    meta: indexBqiuZpk0bsMeta || {},
    alias: indexBqiuZpk0bsMeta?.alias || [],
    redirect: indexBqiuZpk0bsMeta?.redirect || undefined,
    component: () => import("/app/pages/profiles/index.vue").then(m => m.default || m)
  },
  {
    name: indexBqiuZpk0bsMeta?.name ?? "profiles___en",
    path: indexBqiuZpk0bsMeta?.path ?? "/en/profiles",
    meta: indexBqiuZpk0bsMeta || {},
    alias: indexBqiuZpk0bsMeta?.alias || [],
    redirect: indexBqiuZpk0bsMeta?.redirect || undefined,
    component: () => import("/app/pages/profiles/index.vue").then(m => m.default || m)
  },
  {
    path: registermu8R7rSP5uMeta?.path ?? "/register",
    children: [
  {
    name: indexhOBcdtdQ4pMeta?.name ?? "register___fr",
    path: indexhOBcdtdQ4pMeta?.path ?? "",
    meta: indexhOBcdtdQ4pMeta || {},
    alias: indexhOBcdtdQ4pMeta?.alias || [],
    redirect: indexhOBcdtdQ4pMeta?.redirect || undefined,
    component: () => import("/app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: personalpG2OpCiAMqMeta?.name ?? "register-personal___fr",
    path: personalpG2OpCiAMqMeta?.path ?? "personal",
    meta: personalpG2OpCiAMqMeta || {},
    alias: personalpG2OpCiAMqMeta?.alias || [],
    redirect: personalpG2OpCiAMqMeta?.redirect || undefined,
    component: () => import("/app/pages/register/personal.vue").then(m => m.default || m)
  },
  {
    name: products0OgH2noNHPMeta?.name ?? "register-products___fr",
    path: products0OgH2noNHPMeta?.path ?? "products",
    meta: products0OgH2noNHPMeta || {},
    alias: products0OgH2noNHPMeta?.alias || [],
    redirect: products0OgH2noNHPMeta?.redirect || undefined,
    component: () => import("/app/pages/register/products.vue").then(m => m.default || m)
  }
],
    name: registermu8R7rSP5uMeta?.name ?? undefined,
    meta: registermu8R7rSP5uMeta || {},
    alias: registermu8R7rSP5uMeta?.alias || [],
    redirect: registermu8R7rSP5uMeta?.redirect || undefined,
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    path: registermu8R7rSP5uMeta?.path ?? "/en/register",
    children: [
  {
    name: indexhOBcdtdQ4pMeta?.name ?? "register___en",
    path: indexhOBcdtdQ4pMeta?.path ?? "",
    meta: indexhOBcdtdQ4pMeta || {},
    alias: indexhOBcdtdQ4pMeta?.alias || [],
    redirect: indexhOBcdtdQ4pMeta?.redirect || undefined,
    component: () => import("/app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: personalpG2OpCiAMqMeta?.name ?? "register-personal___en",
    path: personalpG2OpCiAMqMeta?.path ?? "personal",
    meta: personalpG2OpCiAMqMeta || {},
    alias: personalpG2OpCiAMqMeta?.alias || [],
    redirect: personalpG2OpCiAMqMeta?.redirect || undefined,
    component: () => import("/app/pages/register/personal.vue").then(m => m.default || m)
  },
  {
    name: products0OgH2noNHPMeta?.name ?? "register-products___en",
    path: products0OgH2noNHPMeta?.path ?? "products",
    meta: products0OgH2noNHPMeta || {},
    alias: products0OgH2noNHPMeta?.alias || [],
    redirect: products0OgH2noNHPMeta?.redirect || undefined,
    component: () => import("/app/pages/register/products.vue").then(m => m.default || m)
  }
],
    name: registermu8R7rSP5uMeta?.name ?? undefined,
    meta: registermu8R7rSP5uMeta || {},
    alias: registermu8R7rSP5uMeta?.alias || [],
    redirect: registermu8R7rSP5uMeta?.redirect || undefined,
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    path: renewoZHCwPTQaYMeta?.path ?? "/renew",
    children: [
  {
    name: indexwfiOBUrUy9Meta?.name ?? "renew___fr",
    path: indexwfiOBUrUy9Meta?.path ?? "",
    meta: indexwfiOBUrUy9Meta || {},
    alias: indexwfiOBUrUy9Meta?.alias || [],
    redirect: indexwfiOBUrUy9Meta?.redirect || undefined,
    component: () => import("/app/pages/renew/index.vue").then(m => m.default || m)
  },
  {
    name: personaloVE68LnQpoMeta?.name ?? "renew-personal___fr",
    path: personaloVE68LnQpoMeta?.path ?? "personal",
    meta: personaloVE68LnQpoMeta || {},
    alias: personaloVE68LnQpoMeta?.alias || [],
    redirect: personaloVE68LnQpoMeta?.redirect || undefined,
    component: () => import("/app/pages/renew/personal.vue").then(m => m.default || m)
  },
  {
    name: products2MpwGFqUW9Meta?.name ?? "renew-products___fr",
    path: products2MpwGFqUW9Meta?.path ?? "products",
    meta: products2MpwGFqUW9Meta || {},
    alias: products2MpwGFqUW9Meta?.alias || [],
    redirect: products2MpwGFqUW9Meta?.redirect || undefined,
    component: () => import("/app/pages/renew/products.vue").then(m => m.default || m)
  }
],
    name: renewoZHCwPTQaYMeta?.name ?? undefined,
    meta: renewoZHCwPTQaYMeta || {},
    alias: renewoZHCwPTQaYMeta?.alias || [],
    redirect: renewoZHCwPTQaYMeta?.redirect || undefined,
    component: () => import("/app/pages/renew.vue").then(m => m.default || m)
  },
  {
    path: renewoZHCwPTQaYMeta?.path ?? "/en/renew",
    children: [
  {
    name: indexwfiOBUrUy9Meta?.name ?? "renew___en",
    path: indexwfiOBUrUy9Meta?.path ?? "",
    meta: indexwfiOBUrUy9Meta || {},
    alias: indexwfiOBUrUy9Meta?.alias || [],
    redirect: indexwfiOBUrUy9Meta?.redirect || undefined,
    component: () => import("/app/pages/renew/index.vue").then(m => m.default || m)
  },
  {
    name: personaloVE68LnQpoMeta?.name ?? "renew-personal___en",
    path: personaloVE68LnQpoMeta?.path ?? "personal",
    meta: personaloVE68LnQpoMeta || {},
    alias: personaloVE68LnQpoMeta?.alias || [],
    redirect: personaloVE68LnQpoMeta?.redirect || undefined,
    component: () => import("/app/pages/renew/personal.vue").then(m => m.default || m)
  },
  {
    name: products2MpwGFqUW9Meta?.name ?? "renew-products___en",
    path: products2MpwGFqUW9Meta?.path ?? "products",
    meta: products2MpwGFqUW9Meta || {},
    alias: products2MpwGFqUW9Meta?.alias || [],
    redirect: products2MpwGFqUW9Meta?.redirect || undefined,
    component: () => import("/app/pages/renew/products.vue").then(m => m.default || m)
  }
],
    name: renewoZHCwPTQaYMeta?.name ?? undefined,
    meta: renewoZHCwPTQaYMeta || {},
    alias: renewoZHCwPTQaYMeta?.alias || [],
    redirect: renewoZHCwPTQaYMeta?.redirect || undefined,
    component: () => import("/app/pages/renew.vue").then(m => m.default || m)
  },
  {
    name: unverifiedCxsaUjVRgbMeta?.name ?? "unverified___fr",
    path: unverifiedCxsaUjVRgbMeta?.path ?? "/unverified",
    meta: unverifiedCxsaUjVRgbMeta || {},
    alias: unverifiedCxsaUjVRgbMeta?.alias || [],
    redirect: unverifiedCxsaUjVRgbMeta?.redirect || undefined,
    component: () => import("/app/pages/unverified.vue").then(m => m.default || m)
  },
  {
    name: unverifiedCxsaUjVRgbMeta?.name ?? "unverified___en",
    path: unverifiedCxsaUjVRgbMeta?.path ?? "/en/unverified",
    meta: unverifiedCxsaUjVRgbMeta || {},
    alias: unverifiedCxsaUjVRgbMeta?.alias || [],
    redirect: unverifiedCxsaUjVRgbMeta?.redirect || undefined,
    component: () => import("/app/pages/unverified.vue").then(m => m.default || m)
  },
  {
    name: verification4hxpuJdPABMeta?.name ?? "verification___fr",
    path: verification4hxpuJdPABMeta?.path ?? "/verification",
    meta: verification4hxpuJdPABMeta || {},
    alias: verification4hxpuJdPABMeta?.alias || [],
    redirect: verification4hxpuJdPABMeta?.redirect || undefined,
    component: () => import("/app/pages/verification.vue").then(m => m.default || m)
  },
  {
    name: verification4hxpuJdPABMeta?.name ?? "verification___en",
    path: verification4hxpuJdPABMeta?.path ?? "/en/verification",
    meta: verification4hxpuJdPABMeta || {},
    alias: verification4hxpuJdPABMeta?.alias || [],
    redirect: verification4hxpuJdPABMeta?.redirect || undefined,
    component: () => import("/app/pages/verification.vue").then(m => m.default || m)
  }
]