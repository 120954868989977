{
  "date_format": {
    "date_format": ""
  },
  "pages": {
    "404": {
      "404_title": "404",
      "page_not_found_label": "Oops... page note found",
      "text_label": "In everything we have seen, we can't seem to find the page you're looking for",
      "back_tohome_btn": "Back to homepage"
    },
    "header": {
      "logo_alt": "Logo that represent the network",
      "logo_text": "Link to the homepage",
      "cart_btn_alt": "Go to cart",
      "tracking_btn": "Tracking inscriptions",
      "account_btn": "My account",
      "logout_btn": "Logout",
      "tracking_desc": "tacks the status of your orders",
      "account_desc": "manage my account",
      "profiles_btn": "childs profiles",
      "profiles_desc": "manage the childs profiles",
      "technical_difficulty": "Are you experiencing technical difficulties?",
      "click_on": "Click on",
      "this_link": "this link",
      "restart_procedure": "to restart the procedure."
    },
    "footer": {
      "logo_airweb_alt": "Logo that represents the company Airweb",
      "logo_airweb_sr": "Link towards the airweb website",
      "legal_notice": "Legal Notice",
      "contact": "Contact",
      "lang_selector_placeholder": "Lang selector",
      "powered_by": "Powered by",
      "version": "v{version}"
    },
    "school_index": {
      "title": "{0} school store",
      "btn_register": "First inscription",
      "btn_login": "I already have an account"
    },
    "home": {
      "urban_store_alt": "Illustration that represents a bus stop",
      "urban_store_btn": "Access to the urban store",
      "school_store_alt": "Illustration that represents two classmates",
      "school_store_btn": "Access to the school store"
    },
    "auth_login": {
      "title": "Access your account",
      "alert_text": "Sign in to access your order history or re-subscribe",
      "login_label": "Login (email)",
      "login_placeholder": "Fill in your login (email)",
      "password_label": "Password",
      "password_placeholder": "Fill in your password",
      "password_reset_link": "Forgotten password ?",
      "submit_label": "Login",
      "alert_catch_all": "Error during connexion",
      "user_deactivated": "This account has been turned off. Please contact our support",
      "alert_user_disabled": "This user is temporarily blocked",
      "alert_login_incorrect": "This login/password combination is not recognized",
      "alert_too_many_attempts": "Your account is temporarily blocked. Please try again later."
    },
    "school_register_child": {
      "title": "School information",
      "school_travel_title": "Information on the school travel of the child",
      "zone_name_label": "Dwelling place",
      "zone_name_placeholder": "Please select your child's dwelling place",
      "school_zone_label": "School area",
      "school_zone_placeholder": "Please select your school area",
      "school_schooling_title": "Information on the grade",
      "school_level_label": "Grade of the child",
      "school_level_placeholder": "Please select the grade of the child",
      "school_name_label": "Name of the school",
      "school_name_placeholder": "Please select your child's school",
      "school_class_label": "Inscription class",
      "school_class_placeholder": "Please select the inscription class",
      "school_retaurant_label": "Does your kid goes to the school restaurant ?",
      "school_retaurant_placeholder": "Please select whether your kid goes the school restaurant or not ?",
      "school_busline_label": "Bus line",
      "school_busline_placeholder": "Please select the bus line adapted to go to school",
      "mmph_label": "Do you have MMPH ?",
      "mmph_yes_label": "My child have MMPH",
      "mmph_no_label": "My child doesn't have MMPH",
      "social_fund_label": "Social fund",
      "social_fund_yes_label": "My child have social fund",
      "social_fund_no_label": "My child doesn't have social fund",
      "back_button": "Back to the previous step",
      "submit_button": "Next step",
      "cart_title": "Your cart",
      "steps_title": "Steps",
      "empty_cart": "Empty cart",
      "info_contact": "Special case? Large family? Recently arrived on the territory? Don't hesitate to contact us"
    },
    "school_personal_information": {
      "title": "Personal informations",
      "documents_section_title": "Documents",
      "personal_information_section_title": "Personal informations",
      "profile_picture_label": "Profile picture for the card",
      "lastname_label": "Last name",
      "lastname_placeholder": "Please enter the last name of the child",
      "firstname_label": "First name",
      "firstname_placeholder": "Please enter the first name of the child",
      "relation_label": "Relationship regarding the legal representative",
      "relation_placeholder": "Please select the relationship regarding the legal representative",
      "contact_section_title": "Contact informations",
      "street_label": "Street address",
      "street_placeholder": "Please enter the street address where the child lives",
      "postalcode_label": "Postal code",
      "postalcode_placeholder": "Please enter the postal code where the child lives",
      "city_label": "City",
      "city_placeholder": "Please enter the city where the child lives",
      "country_label": "Country",
      "country_placeholder": "Please enter the country where the child lives",
      "email_label": "Email",
      "email_placeholder": "Please enter the email of the child",
      "phone_label": "Phone number",
      "phone_placeholder": "Please enter the phone number of the child",
      "documents_upload_placeholder": "Download the document: {0}",
      "add_child_button": "Add a new child",
      "alternating_custody_label": "Alternating custody",
      "alternating_custody_yes": "The child has an alternating custody",
      "alternating_custody_no": "The Child doesn't have an alternating custody",
      "alternating_custody_section_title": "Alternating Custody Information",
      "alternating_custody_client_zone2_label": "Secondary child home",
      "alternating_custody_client_zone2_placeholder": "Select the second home of the child",
      "alternating_custody_busline2_label": "Busline",
      "alternating_custody_busline2_placeholder": "Busline from the second home",
      "no_data_found": "No information found",
      "download_model": "Download model",
      "student_birthday": "Please enter the student's date of birth",
      "max_file_size_exceeded": "The file is too large",
      "wrong_file_type": "Incorrect file type",
      "error_upload_file": "An error occurred while uploading the file",
      "error_message_field_required": "Not all required fields have been completed. Please fill in the missing fields before proceeding to the next step.",
      "collect_message": "This information is required to create the student's transport card.",
      "collect_message_renew": "This information is required to create or renew the student's transport card."
    },
    "school_products": {
      "title": "Choice of product",
      "noresult_title": "No results",
      "noresult_text": "No results matching your search were found.\nPlease modify your criteria in the previous step or contact your transport network.",
      "demat_label": "On my mobile app",
      "demat_description": "Immediate use with mobile app",
      "physical_label": "On my travel card",
      "click_and_collect_label": "On click and collect",
      "click_and_collect_description": "Mobility agency delivery",
      "shipping_label": "At home",
      "shipping_description": "Delivery to your door"
    },
    "school_sidebar": {
      "schooling_title": "Schooling",
      "schooling_description": "Fill in information related to the bus line the child takes as well as the school",
      "product_title": "Select your product",
      "product_description": "Select the product for your child",
      "information_title": "Personal information",
      "information_description": "Download the documents and the personal information of the child",
      "download_explain": "Download the supporting documents and enter the student's personal details."
    },
    "summary": {
      "title": "Registration summary",
      "count_child": "Child {0}"
    },
    "school_register_help": {
      "title": "Any questions related to the inscription?",
      "description": "Call us if you have any question at the following phone number",
      "illustration_alt": "Illustration of a person holding a question mark"
    },
    "cart": {
      "title": "Basket",
      "sidebar_title": "Your basket",
      "sidebar_total": "Total",
      "sidebar_terms": "I accept the {sidebar_terms_link}",
      "sidebar_terms_link": "terms",
      "sidebar_order_btn": "Order",
      "cart_item_verify_btn": "Verify the infos.",
      "cart_item_delete_btn": "Delete",
      "cart_item_profile_picture_alt": "Profile picture of the child",
      "cart_item_product_picture_alt": "Photo of the title selected",
      "empty_cart": "Empty cart...",
      "add_child_text": "Add a new child to your inscription to make a single payment!",
      "add_child_btn": "Add a new child to the inscription",
      "add_child_img_alt": "A child playing with a car",
      "delete_item_title": "Do you really want to delete this item?",
      "delete_item_text": "Deleting a product will also make you lose data and the documents regarding the child linked to that product.",
      "cancel_item_btn": "Cancel",
      "delete_item_btn": "Delete",
      "add": "Add",
      "discount_codes": "Discount codes",
      "discount_codes_not_applied": "The following discount codes could not be applied:",
      "code": "Code",
      "error_unknown": "The code is unknown",
      "error_code_expired": "The code has expired",
      "error_code_inactive": "The code is no longer valid",
      "error_code_rules_not_met": "The code cannot be applied to the current order",
      "error_code_limit_reached": "The code has reached its maximum number of uses",
      "discount_code": "Discount code",
      "discount_code_placeholder": "Enter your discount code",
      "discount": "Discount",
      "dematerialized": "On my app",
      "collect": "Click and collect",
      "media": "On my card",
      "shipping": "Home delivery",
      "delivery": "Place of delivery",
      "home_delivery": "Home delivery:"
    },
    "cart_account": {
      "title": "Account creation",
      "info_title": "Personal information about the account owner",
      "info_picture_label": "Profile picture",
      "info_picture_alt": "",
      "info_lastname_label": "Last Name",
      "info_lastname_placeholder": "Enter your last name",
      "info_firstname_label": "First Name",
      "info_firstname_placeholder": "Enter your first name",
      "info_birthday_label": "Birthday",
      "info_birthday_placeholder": "Enter your birthday",
      "contact_title": "Contact information",
      "contact_street_label": "Street address",
      "contact_street_placeholder": "Enter your street address",
      "contact_postalcode_label": "Postal Code",
      "contact_postalcode_placeholder": "Enter your postal code",
      "contact_city_label": "City",
      "contact_city_placeholder": "Enter your city",
      "contact_country_label": "Country",
      "contact_country_placeholder": "Enter your country",
      "contact_phone_label": "Phone number",
      "contact_phone_placeholder": "Enter your phone number",
      "login_title": "Connection credentials",
      "login_email_label": "Email",
      "login_email_placeholder": "Enter your email (which will be your login)",
      "login_password_label": "Password",
      "login_password_placeholder": "Enter your password",
      "login_confirm_label": "Password confirmation",
      "login_confirm_placeholder": "Confirm your password",
      "login_email_confirm": "Confirm your email",
      "login_email_confirm_placeholder": "Please confirm your email, it cannot be changed",
      "invalid_form": "The form is invalid",
      "confirmation_mail_failed": "Email address confirmation failed",
      "password_not_same": "New password confirmation does not match new password"
    },
    "cart_delivery": {
      "title": "Delivery",
      "delivery_title": "Delivery address",
      "delivery_street_label": "Street address",
      "delivery_street_placeholder": "Enter your delivery street address",
      "delivery_postalcode_label": "Postal Code",
      "delivery_postalcode_placeholder": "Enter your delivery city postal code",
      "delivery_city_label": "City",
      "delivery_city_placeholder": "Enter your delivery city name",
      "delivery_country_label": "Country",
      "delivery_country_placeholder": "Enter your delivery country",
      "delivery_next_step_btn": "Validate the information",
      "delivery_validate_order_btn": "Validate the order",
      "create_account": "Create an account"
    },
    "cart_checkout": {
      "title": "Checkout",
      "card_title": "Credit card",
      "eficash_title": "Eficache",
      "agency_title": "Within an agency",
      "mean_btn": "I chose this payment method",
      "mean_selected": "Selected payment method",
      "order_confirmed": "The order has been confirmed. You can find your invoice in the My Account area.",
      "need_verified": "To make a purchase, your account must be verified.",
      "resend_confirmation_mail": "Resend confirmation email",
      "password_too_short": "The password entered is too weak: it must contain at least 6 characters.",
      "email_already_verified": "Your email has already been verified",
      "wait": "Please wait a few moments before repeating your account confirmation request.",
      "mail_sended": "An email has been sent to you",
      "error_guest_order_not_allowed": "Guest mode is no longer available. Please log in to your customer account or create a new account to continue shopping.",
      "error_order_amount_exceeded": "By adding this title to your shopping cart, you have exceeded the maximum order amount allowed in guest mode. Please log in to your customer account or create an account to access all purchasing features.",
      "error_order_article_document_invalid": "At least one item in your basket is invalid",
      "error_order_article_collect_required": "At least one item in your basket is to be picked up at a branch, but you have not selected a pick-up location.",
      "preauthorized": "The transaction linked to your order will be validated manually by the network. You will not be debited for the amount paid and your order will not be credited before this manual validation.\nIf your order is not validated by the network within 6 days, no amount will be debited and your order will be cancelled."
    },
    "cart_checkout_card": {
      "payment_type_title": "Payment commitment",
      "payment_type_label": "Select the desired payment commitment",
      "payment_type_1x": "1x",
      "payment_type_3x": "3x",
      "payment_type_10x": "10x",
      "payment_type_btn": "Pay",
      "alert_payment": "This type of payment is temporaly unvailable. Please try again later.",
      "payment_type_dynamic": "Pay in {0}x",
      "barcode_description_part1": "To finalize your registration, please click on the “Pay” button.",
      "barcode_description_part2": "Your registration form will be validated once the payment by credit card has been made and after the supporting documents have been processed by the services.",
      "barcode_description_part3": "We invite you to visit the \\\"Tracking inscriptions\\\" section in your customer space to check the status of your order.",
      "cash_payment": "Cash payment",
      "monthly_payment": "Payment in {month} monthly instalments"
    },
    "cart_checkout_eficash": {
      "barcode_title": "Eficash barcode",
      "barcode_description_part1": "To finalize your registration, please click on the “Confirm my registration” button.",
      "barcode_btn": "Confirm my registration",
      "barcode_description_part2": "You will receive an email containing a link to download the EFICASH document.",
      "barcode_description_part3": "Show this document to your post office with the amount of your cash transaction.",
      "barcode_description_part4": "Your registration form will be validated once the payment has been made at the post office and after the documents have been processed by the services.",
      "barcode_description_part5": "We invite you to visit the \\\"Tracking inscriptions\\\" section in your customer space to check the status of your order."
    },
    "cart_checkout_agency": {
      "agency_title": "Pay within an agency",
      "agency_btn": "Validate my inscription",
      "agency_description": "To end your inscription you'll be required to go to agency with an amount of money equal to your basket.\n\nYour inscription will then be validated once your payment is accepted."
    },
    "stepper": {
      "basket": "Basket",
      "account": "Account",
      "delivery": "Delivery",
      "checkout": "Checkout"
    },
    "legals": {
      "title": "Legal terms"
    },
    "school_auth_login": {
      "title": "Inscription for school transport",
      "form_title": "I already have an account",
      "login_email_label": "Login",
      "login_email_placeholder": "Enter your email",
      "login_password_label": "Password",
      "login_password_placeholder": "Enter your password",
      "forgotten_password": "Forgotten password?",
      "submit_btn": "Login",
      "register_title": "First visit?",
      "register_btn": "Register",
      "tuto_title": "I already have an account",
      "tuto_subtitle": "How to top up my subscription?",
      "text_step_1": "Select the child's subscription you wish to topup",
      "text_step_2": "Verify the information from the previous year and update if needed",
      "text_step_3": "Finish the transaction",
      "illustration1_alt": "Illustration of two children with their schoolbag",
      "illustration2_alt": "Illustration of a girl holding an identity card",
      "illustration3_alt": "Illustration of a woman with a credit card",
      "alert_notrecognized": "Your account or password is not recognized.Please try again or register from the {alert_link_register}.",
      "alert_link_register": "registration process"
    },
    "school_auth_register": {
      "title": "Inscription for school transport",
      "subtitle": "First time registering",
      "subsubtitle": "What are the steps to register?",
      "text_step1": "Fill in the information of your child(s)",
      "text_step2": "Choose the right product",
      "text_step3": "Fill in the documents of the child and his personal informations",
      "text_step4": "Fill in your personal information and create your account",
      "text_step5": "Proceed to finish the transaction",
      "next_step_btn": "Proceed to register",
      "login_btn": "I already have an account",
      "illustration1_alt": "Illustration of a school",
      "illustration2_alt": "Illustration of a girl holding an identity card",
      "illustration3_alt": "Illustration of two children with their schoolbag",
      "illustration4_alt": "Illustration of a father playing with his son",
      "illustration5_alt": "Illustration of a woman with a credit card",
      "signup": "I signup"
    },
    "empty_cart": {
      "title": "Your basket is empty",
      "illustration_alt": "Illustration of a person near an empty shopping cart",
      "add_child_btn": "ADD A NEW CHILD TO THE INSCRIPTION"
    },
    "account_menu_sidebar": {
      "profile_btn": "My account owner profile",
      "invoices_btn": "My invoices",
      "payment_cards_btn": "My payment cards",
      "open_menu": "Open the menu"
    },
    "account_form": {
      "bloc1_title": "My account owner profile",
      "picture_label": "Profile picture",
      "lastname_label": "Last name",
      "lastname_placeholder": "Enter your last name",
      "firstname_label": "First name",
      "firstname_placeholder": "Enter your first name",
      "birthday_label": "Birthday",
      "bloc2_title": "My contact information",
      "address_label": "Street address",
      "address_placeholder": "Enter your address",
      "zipcode_label": "Zip code",
      "zipcode_placeholder": "Enter your zip code",
      "city_label": "City",
      "city_placeholder": "Enter your city",
      "country_label": "Country",
      "country_placeholder": "Enter your country",
      "phone_label": "Phone number",
      "phone_placeholder": "Enter your phone number",
      "bloc3_title": "My login credentials",
      "email_label": "Email (your login)",
      "password_label": "Edit my password",
      "current_password_label": "Current password",
      "current_password_placeholder": "Enter your current password",
      "new_password_label": "New password",
      "new_password_placeholder": "Enter your new password",
      "confirm_new_password_label": "Confirm new password",
      "confirm_new_password_placeholder": "Confirm your new password",
      "submit_btn": "Submit the information",
      "email_address": "Email address",
      "password_min": "At least 12 characters",
      "password_has_uppercase": "At least one capital letter",
      "password_has_lowercase": "At least one lower-case letter",
      "password_has_number": "At least one number",
      "password_has_special_chars": "At least one special character",
      "birthdate_placeholder": "Please enter your date of birth",
      "firstname_no_numbers": "No figures available",
      "invalid_password": "Current password is incorrect",
      "weak_password": "The new password is too weak.",
      "error_password_update": "An error occurred while updating the password",
      "error_info": "An error occurred while updating the information",
      "password_confirmation_not_match": "New password confirmation does not match new password",
      "different_password": "You must enter a new password, different from the current one.",
      "invalid_form": "invalid form",
      "delete_account": "Delete my account"
    },
    "account_tuto": {
      "tuto1_title": "Why enter “my account owner profile“?",
      "tuto1_detail": "This allows the transport network to identify you as responsible for the children, for whom you have submitted application.",
      "tuto2_title": "Why enter “my contact information“?",
      "tuto2_detail": "This information makes it possible to contact you if necessary and to keep you infrmed of events on school lines.",
      "tuto3_title": "Why enter “my login credentials“?",
      "tuto3_detail": "Your login email cannot be modified. It allows you to connect to the school shop website. You can change your passeword at anytime in the “Change my password“ section."
    },
    "account_invoices": {
      "account_title": "My account",
      "invoices_title": "My invoices",
      "date_label": "Date",
      "amount_label": "Amount",
      "preview_label": "Download",
      "tuto_invoices_title": "What is the “My invoices” ?",
      "filter_icon_alt": "Filter invoices by date",
      "preview_icon_alt": "see the invoice",
      "file_title_label": "invoice_No_",
      "tuto_invoices_detail": "In this section, you will find the invoices for your orders on this website.\nThe invoice is available after the validation of your apply for inscription.\nYou can download each invoice in pdf format."
    },
    "empty_invoices": {
      "noivoice_label": "No invoice available",
      "tracking_btn": "Go to tracking requests",
      "illustration_invoice_alt": "illustration of an invoice"
    },
    "account_payment_cards": {
      "account_title": "My account",
      "credit_card_title": "My payment cards",
      "card_number_label": "Card number",
      "date_label": "Date",
      "delete_label": "Delete",
      "tuto_cards_title": "What is the “My payment cards“ section ?",
      "popup_delete_card_title": "Do you really want to delete this card?",
      "popup_delete_label": "If you delete this card, you will have to either selet another registered card or re-enter your bank information during your next transaction.",
      "cancel_card_btn": "Cancel",
      "delete_card_btn": "Delete",
      "delete_icon_alt": "Delete payment card",
      "tuto_cards_detail": "You will find the cards you saved during your previous payments.\nIf you delete your cards, you will have to re-enter your banking information during your next transactions.",
      "error_delete": "Error when deleting card"
    },
    "empty_payment_cards": {
      "nocards_label": "No payment card available",
      "shop_btn": "Reload a school pass",
      "illustration_payment_cards_alt": "Payment cards ilustration"
    },
    "tracking_inscriptions": {
      "tracking_inscriptions_title": "Tracking inscriptions",
      "document_refused_alert": "Documents of your applications have been refused.Please, update your documents by  cliking on \\\"see details\\\" in your apply for inscription.Please note, from the date of your application you have 5 days maximum to update your documents otherwise the request will be automatically canceled.",
      "request_validated_alert": "Your inscription has been accepted. the amount of your order will be invoiced, you can find your invoice in “My account“.",
      "application_number_label": "Application No {0}",
      "status_accepted_label": "inscription accepted",
      "status_refused_label": "inscription to complete",
      "status_pending_label": "pending inscription",
      "detail_btn": "see detail",
      "inscription_refused": "inscription refused",
      "inscription_accepted": "inscription accepted",
      "inscription_pending": "pending inscription",
      "tuto_processing_time_title": "How long is my application processed?",
      "tuto_inscription_refused_title": "Why is my inscription to be completed?",
      "tuto_inscription_refused_label": "Your application may be to complete because a document doesn't match the request.\nTo see the details of your application click on “see detail“.",
      "tuto_processing_time_label": "Your school transport network at 6 days to validate your apply for inscription.\nAfter checking, your school pass will be sent to you according to the shipping method chosen."
    },
    "empty_tracking_inscription": {
      "tracking_request_title": "Tracking inscriptions",
      "norequest_label": "No application in being processed",
      "shop_btn": "Renew an inscription",
      "illustration_alt": "illustration of a man standing near a big add symbol"
    },
    "tracking_detail": {
      "tracking_detail_title": "details of the inscription",
      "application_number_label": "Application No {0}",
      "status_refused_label": "Inscription to complete",
      "status_accepted_label": "Inscription accepted",
      "inscription_accepted_label": "inscription accepted",
      "inscription_refused_label": "inscription refused",
      "document_label": "document",
      "status_label": "statut",
      "preview_label": "preview",
      "update_label": "update",
      "document_accepted": "document accepted",
      "document_refused": "document rejected",
      "preview_icon_alt": "See the document",
      "update_icon_alt": "update the rejected document",
      "update_icon_notactive_alt": "document already accepted",
      "tuto_update_title": "How to update my request?",
      "tuto_update_detail_label": "Updated your rejected document and click on “Send update“ to alert your school transport network of your new document.",
      "send_update_btn": "Send update",
      "status_pending_label": "Pending inscription",
      "inscription_pending": "pending inscription",
      "document_pending": "pending document",
      "error_invoices": "Error retrieving invoices",
      "go_to_student_subscribers": "Access to student subscribers",
      "finish_shopping": "Finish shopping"
    },
    "popup_payment": {
      "validated_title": "Order confirmed",
      "validated_label": "Order confirmed.You will be redirected to the inscription tracking page.",
      "error_title": "Payment failed",
      "error_label": "Payment has been refused.You will be redirected to the cart.",
      "cancelled_title": "Payment cancelled",
      "cancelled_label": "Payment has been cancelled.You will be redirected to the cart.",
      "btn_tracking": "Go to the tracking",
      "btn_cart": "Go to the cart",
      "btn_close": "Close"
    },
    "home_profiles": {
      "subscribed_children_title": "Subscribed children",
      "edit_profile_btn": "edit profile",
      "renew_inscription_btn": "Renew inscription",
      "duplicata_btn": "Request a duplicate",
      "add_btn": "Add child",
      "tuto_renew_title": "how to renew the inscription ?",
      "tuto_renew_txt": "Click on the “Renew inscription” button and check the information entered during your previous purchase and update data to define the child's new pass.",
      "tuto_add_title": "how to add a child ?",
      "tuto_add_txt": "Click on the “Add child” button and continue the steps. After your order the inscription will appear in the list of subscribed children.",
      "badge_label": "inscription added to cart",
      "student_subscribers": "Student subscribers"
    },
    "profile": {
      "profile_title": "Child profile",
      "personnal_info_title": "Personal information",
      "picture_label": "Pass profile picture",
      "edit_picture_btn": "Edit picture",
      "lastname_label": "Last name",
      "kinship_label": "kinship with the account owner",
      "school_info_title": "School information",
      "dwelling_label": "Dwelling place",
      "school_label": "Name of school",
      "cafeteria_status_label": "registration for boarding school or cafeteria",
      "bus_line_label": "Bus line",
      "contact_info_title": "Contact info",
      "email_label": "Email",
      "phone_label": "Phone number",
      "tuto_info_title": "Where does this information come from ?",
      "firstname_label": "Firstname",
      "tuto_info_txt": "The information on this page is the elements entered during your last inscription.\nYou can modify your contact and personal info but the School information may be changed when you next inscription.",
      "exlpain_temporary_photo": "Temporary photo, to validate the modification, click on \\\"Validate information\\\"."
    },
    "auth_reset": {
      "title": "Forgotten password",
      "label": "Connection email",
      "placeholder": "Fill in your connection email",
      "cancel": "Cancel",
      "submit": "Reset",
      "alert_error1": "Invalide email",
      "alert_error2": "Error during reset. Try again.",
      "alert_success": "Si un compte existe avec cette adresse email, un email de réinitialisation a été envoyé.",
      "error_message": "An error occurred when you reset your password. The address entered may not be in the correct format. If the problem persists, please contact support."
    },
    "duplicata": {
      "title": "Duplicate request",
      "label_personal_info": "Personal information",
      "label_picture": "Profile picture",
      "change_picture_btn": "Change the photo",
      "label_last_name": "Last name",
      "label_first_name": "First name",
      "label_reasons_request": "Reason for the request",
      "label_reason_selector": "Select the reason for the request",
      "label_stolen_pass": "The pass was stolen and i have a complaint document",
      "label_lost_pass": "The pass has been lost",
      "label_documents": "Document(s)",
      "placeholder_upload": "Upload document",
      "back_btn": "Back",
      "title_tuto_duplicata": "Why have a complaint document?",
      "text_tuto_duplicata": "If you have a complaint document related to the theft of your pass, the duplicated will be free.",
      "error_image": "Error updating profile picture",
      "error_update_profil": "Error updating profile",
      "no_profil_selected": "No profile selected",
      "cannot_duplicate": "The selected profile cannot request a duplicate."
    },
    "clear": {
      "cache_clear": "We clear your cache",
      "infinit_loader": "Does the page load indefinitely?",
      "back_to_shop": "Back to the store"
    },
    "unverified": {
      "need_confirm_account": "Your account must be verified",
      "security_verified_account": "For added security, your account must be verified.",
      "resend_mail": "Resend confirmation email",
      "close": "Close",
      "email_has_been_send": "An email has been sent to you",
      "email_with_link": "An email with a confirmation link has been sent to this email address:",
      "can_buy_with_link": "You will be able to buy tickets again by clicking on the link sent to you by email."
    },
    "verification": {
      "please_confirm_signup": "Please confirm your registration",
      "received_email": "You have received an email at",
      "please_confirm": "Please click on the confirmation link in the email to confirm your registration.",
      "please_confirm_explain": "Account verification is required to purchase your ticket.",
      "go_to_mail": "Access my emails",
      "back_to_cart": "Back to basket"
    },
    "help_sidebar": {
      "blocked": "I'm stuck",
      "schedule_appointment": "I schedule an appointment",
      "problem_description": "If you are still experiencing technical difficulties even after resetting your session, you can schedule an appointment with us by clicking on the button below.",
      "problem_title": "Problems persist",
      "reset_session": "Resetting my session",
      "difficulty_description_1": "You can click on the button below to restart the registration process.",
      "difficulty_description_2": "This button will reset your session and allow you to restart the procedure."
    },
    "disabled_profile": {
      "title": "Disabled {firstName}'s account",
      "description": "Are you sure you want to disable {lastName} {firstName}'s profile ?",
      "warning": "If you wish to renew your child's subscription, you will need to re-register him/her.",
      "disabled": "Disabled",
      "success_message": "The account is disabled"
    },
    "maintenance": {
      "title": "Site currently under maintenance",
      "description": "Please be patient while we perform maintenance work to improve your user experience. We appreciate your understanding",
      "no_estimated_date": "Please come back later",
      "estimated_date": "Estimated end of maintenance date: {date}",
      "refresh": "Refresh"
    },
    "account_delete": {
      "warning": "Please read the information on account deletion carefully.",
      "delete_info": "The deletion of your account will lead to the definitive deletion of the following data on all applications and shops of the airweb company.",
      "data_to_delete": "List of data to be deleted :",
      "personnal_data": "Your personal data",
      "delivery_address": "Your delivery addresses",
      "payment_method": "Your payment methods",
      "tickets": "Your tickets",
      "networks": "Your linked accounts on the following transport networks:",
      "error_data": "An error has occurred while retrieving information",
      "delay": "You have a 30-day cooling-off period. Without any action on your part, the account will be permanently deleted.",
      "account_details": "Details of related accounts",
      "no_ticket": "This profile has no active tickets",
      "delete": "Delete",
      "tacite_message": "You cannot delete your account because you have one or more tacitly renewed subscriptions. Please cancel the tacit renewal of your subscription(s) before deleting your account.",
      "error_delete": "An error occurred when deleting your account"
    },
    "delete_success": {
      "title": "Register your request",
      "submission_save": "Your request has been registered.",
      "description": "Within a few minutes you will receive an email confirming that your deletion request has been processed.\nIf you do not take any action within 30 days, your account will be permanently deleted without any possibility of recovering your data.\nBy clicking on \\\"I understand\\\", you will be disconnected from your account.",
      "button": "I understand",
      "redirect": "You will be redirected in a few moments"
    },
    "account_reactivation_modal": {
      "text": "Reactivating your account cancels your account deletion. Do you confirm that you want to reactivate your account?"
    }
  },
  "labeldate": {},
  "otherslabels": {
    "fr": "French",
    "en": "English",
    "close_alert_text": "Close the alert",
    "inline_upload_description_btn_alt": "See more informations",
    "inline_upload_delete_btn_alt": "Remove the file",
    "upload_img_alt": "File already uploaded",
    "price_selected": "Selected price",
    "btn_price": "I chose this product",
    "price_card_illustration": "Illustration of child playing",
    "common_loading": "Loading...",
    "common_error": "An error happened",
    "upload_profile_picture": "Add a photo",
    "uploaded_profile_picture": "Change the photo",
    "btn_next_step": "Next step",
    "btn_previous_step": "Previous step",
    "button_tc": "Accept the TOS",
    "popup_tc": "Terms of sell",
    "signup_alert_name_without_vowel": "At least one vowel",
    "signup_alert_invalid_name": "No special characters or numbers",
    "signup_alert_same_name_firstname": "No identical first name and last name",
    "signup_alert_name_characteres_min": "At least 3 characters",
    "signup_alert_invalid_phonenumber": "At least 10 numbers and starts with 0",
    "signup_alert_invalid_email": "Contain the {'@'} character",
    "signup_alert_invalid_password": "At least 6 characters",
    "signup_alert_invalid_password_confirmation": "Identical password",
    "signup_alert_firstname_characteres_min": "At least 3 characters",
    "btn_validate": "Validate information",
    "popup_session_timeout": "Your session has expirated. Please reconnect.",
    "popup_session_timeout_button": "Log in",
    "alert_email_alreadyinuse": "Please log in from the {alert_link_loginpage} and use the forgotten password feature if you have lost your password.",
    "alert_link_loginpage": "login page",
    "popup_session_timeout_title": "Expirated session",
    "alert_update_account_info_success": "Information successfully updated",
    "alert_update_account_picture_success": "Picture successfully updated",
    "alert_update_account_password_success": "Password successfully update",
    "alert_update_account_info_error": "Error updating information",
    "alert_update_account_picture_error": "Error updating picture",
    "alert_update_account_password_error": "Error updating password",
    "input_password_toggle_btn": "Show or hide the password",
    "documents_type_title": "Documents format",
    "documents_type_content": "The documents must be an image (png or jpg) or a pdf",
    "signup_alert_invalid_email_confirmation": "Identical email",
    "line_more_info": "Click here to get more details about the lines",
    "child_older_than_3": "I attest on the honor that the student is over 3 years old",
    "error_details": "Error details",
    "warning": "Warning!",
    "image_type_title": "Student photo format",
    "image_type_content": "The passport photo must not exceed 20 MB in size.",
    "drop_file": "Drop your file here",
    "drop_photo": "Drop your photo here",
    "cancel": "Cancel",
    "wrong_date": "The date entered is incorrect",
    "date_limit": "The date exceeds the maximum limit of {date}.",
    "day": "day",
    "month": "month",
    "year": "year",
    "field_required": "Field required",
    "close": "Close",
    "continue": "Continue"
  },
  "popup_mentions": {},
  "country": {}
}